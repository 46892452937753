import { Button, ButtonGroup } from '@mui/material';
import {
  convertUnitForAssetType,
  getDirectionFromDeg,
  getUnitForAssetType,
} from 'common-web/utils';
import RequestLocationButton from 'components/Assets/Details/RequestLocationButton';
import moment from 'moment';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './popup.scss';
import {
  AspectRatio,
  CloseTwoTone,
  MoreVertTwoTone,
} from '@mui/icons-material';
import ButtonMenu from 'components/ButtonMenu';

const getAssetTypeDom = (assetType?: string) => {
  if (!assetType) return;
  if ('wellhead' !== assetType && 'pipeline' !== assetType) return;

  const text = (() => {
    if ('wellhead' === assetType) return 'Wellhead';
    if ('pipeline' === assetType) return 'Pipeline Monitor';
  })();

  return <small>{text}</small>;
};

const getReportAge = (details: any) => {
  if (details?.timestamp) return moment(details?.timestamp).fromNow();
  if (details?.details?.timestamp)
    return moment(details?.details?.timestamp).fromNow();
  if (details?.assetMedia && details?.assetMedia?.length > 0) {
    if (details?.assetMedia?.[0]?.timestamp) {
      return moment(details?.assetMedia?.[0].timestamp).fromNow();
    }
  }
  return 'never';
};

const shouldShow = (assetType?: string) => {
  return (
    'wellhead' !== assetType &&
    'pipeline' !== assetType &&
    'security_camera' !== assetType
  );
};

const AssetPopup = (
  assetDetails: any,
  setPopup: any,
  navigate: any,
  t: any
) => {
  const isPanic =
    assetDetails?.activeAlerts &&
    assetDetails?.activeAlerts[0]?.alertType === 'panic';

  return (
    <div className={`popup ${isPanic ? 'popup--panic' : 'popup--normal'}`}>
      <div className='popup-inner'>
        <div style={{ marginBottom: 16 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3
              style={{
                marginBottom: 0,
                paddingBottom: 0,
                marginTop: '10px',
              }}>
              {assetDetails?.name || ''}{' '}
            </h3>
            <ButtonGroup
              size='small'
              variant='text'
              color='secondary'
              disableRipple={true}
              disableFocusRipple={true}
              sx={{
                '& .MuiButtonGroup-grouped:not(:last-of-type)': {
                  borderColor: 'transparent',
                },
                '& .MuiButtonGroup-grouped': {
                  minWidth: '30px',
                  padding: 0,
                  margin: '-2px',
                },
                marginRight: '6px',
              }}>
              <ButtonMenu assetDetails={assetDetails} />
              <Button
                onClick={() => navigate(`/assets/${assetDetails?.assetUuid}`)}>
                <AspectRatio fontSize='small' />
              </Button>
              <div style={{ width: 24 }}></div>
              {/* <Button onClick={() => setPopup(null)}>
                <CloseTwoTone fontSize='small' />
              </Button> */}
            </ButtonGroup>
          </div>
          {getAssetTypeDom(assetDetails?.assetType)}
        </div>
        {assetDetails?.assetType === 'security_camera' &&
          assetDetails?.assetMedia?.length > 0 && (
            <div>
              <div className='minfarm-watermarked'>
                <img
                  src={assetDetails?.assetMedia?.[0].fileUrl}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          )}
        <div className='grid-container'>
          {assetDetails?.group && (
            <>
              <div>
                <b>{t('popup.Group', 'Group:')}</b>
              </div>
              <div>{assetDetails?.group?.name || ''}</div>
            </>
          )}
          {assetDetails?.details?.driver && (
            <>
              <div>
                <b>{t('popup.Driver', 'Driver:')}</b>
              </div>
              <div>
                <NavLink
                  to={`/drivers/${assetDetails?.details?.driver?.driverUuid}`}
                  style={{
                    textDecoration: 'none',
                    color: '#70A8CC',
                    textTransform: 'capitalize',
                  }}>
                  {`${assetDetails?.details?.driver?.firstName ?? ''} ${
                    assetDetails?.details?.driver?.lastName ?? ''
                  }`}
                </NavLink>
              </div>
            </>
          )}
          {assetDetails?.details?.callsign && (
            <>
              <div>
                <b>{t('popup.Callsign', 'Callsign:')}</b>
              </div>
              <div>{assetDetails?.details?.callsign}</div>
            </>
          )}
          {assetDetails?.details?.classification && (
            <>
              <div>
                <b>{t('popup.Class', 'Class:')}</b>
              </div>
              <div>{assetDetails?.details?.classification}</div>
            </>
          )}
          {assetDetails?.details?.destination && (
            <>
              <div>
                <b>{t('popup.Destination', 'Destination:')}</b>
              </div>
              <div>{assetDetails?.details?.destination}</div>
            </>
          )}
          {assetDetails?.details?.eta && (
            <>
              <div>
                <b>{t('popup.ETA', 'ETA:')}</b>
              </div>
              <div>{moment(assetDetails?.details?.eta).fromNow()}</div>
            </>
          )}
          {assetDetails?.geoCode && (
            <>
              <div>
                <b>{t('popup.Location', 'Location:')}</b>
              </div>
              <div>{assetDetails?.geoCode}</div>
            </>
          )}
          {assetDetails?.position &&
            assetDetails?.position?.lat &&
            assetDetails?.position?.lon && (
              <>
                <div>
                  <b>{t('popup.Position', 'Position:')}</b>
                </div>
                <div>
                  {`(${assetDetails?.position.lat
                    .toFixed(6)
                    .toString()} ${assetDetails?.position.lon
                    .toFixed(6)
                    .toString()})`}
                </div>
              </>
            )}
          {shouldShow(assetDetails?.assetType) && (
            <>
              <div>
                <b>{t('popup.Speed', 'Speed:')}</b>
              </div>
              <div>
                {convertUnitForAssetType(
                  'speed',
                  assetDetails?.assetType,
                  assetDetails?.speed || 0
                )}{' '}
                {getUnitForAssetType('speed', assetDetails?.assetType)}
              </div>
            </>
          )}
          {shouldShow(assetDetails?.assetType) && (
            <>
              <div>
                <b>{t('popup.Heading', 'Heading:')}</b>
              </div>
              <div>
                {assetDetails?.heading || 0}°{' '}
                {`(${getDirectionFromDeg(assetDetails?.heading || 0)})`}
              </div>
            </>
          )}
          {!shouldShow(assetDetails?.assetType) &&
            assetDetails?.details?.temperature && (
              <>
                <div>
                  <b>{t('popup.Temperature', 'Temperature:')}</b>
                </div>
                <div>{assetDetails?.details?.temperature || 'Unknown'}</div>
              </>
            )}
          {!shouldShow(assetDetails?.assetType) &&
            assetDetails?.details?.pressure && (
              <>
                <div>
                  <b>{t('popup.Pressure', 'Pressure:')}</b>
                </div>
                <div>{assetDetails?.details?.pressure || 'Unknown'}</div>
              </>
            )}
          {!shouldShow(assetDetails?.assetType) &&
            assetDetails?.details?.nominalTemperature && (
              <>
                <div>
                  <b>
                    {t('popup.Nominal Temperature', 'Nominal Temperature:')}
                  </b>
                </div>
                <div>
                  {assetDetails?.details?.nominalTemperature || 'Unknown'}
                </div>
              </>
            )}
          {!shouldShow(assetDetails?.assetType) &&
            assetDetails?.details?.nominalPressure && (
              <>
                <div>
                  <b>{t('popup.Nominal Pressure', 'Nominal Pressure:')}</b>
                </div>
                <div>
                  {assetDetails?.details?.nominalPressure || t('Unknown')}
                </div>
              </>
            )}
          <div>
            <b>{t('popup.Report Age', 'Report Age:')}</b>
          </div>
          <div>{getReportAge(assetDetails)}</div>
        </div>
      </div>
    </div>
  );
};

export default AssetPopup;

import { ScheduleSendOutlined } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import ScheduledReportsList from 'components/ScheduledReports/ScheduledReportsList';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const ScheduledReports = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box
        justifyContent={'space-between'}
        alignItems={'center'}
        display={'flex'}>
        <h1>{t('Scheduled Reports')}</h1>
        <Link to='/reports/scheduler/create'>
          <Button
            color='secondary'
            variant='outlined'
            startIcon={<ScheduleSendOutlined />}>
            {t('Create')}
          </Button>
        </Link>
      </Box>
      <ScheduledReportsList />
    </Box>
  );
};

export default ScheduledReports;

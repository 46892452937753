import React, { useEffect, useState } from 'react';
import { useFormikContext, FieldArray, Field } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import * as Yup from 'yup';
import { Chip, Box, IconButton, Button, Grid, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ExpandLessTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object({
  addRecipient: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
});

const InvoiceRecipients = () => {
  const { t } = useTranslation();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const { values, errors, touched, handleBlur, setFieldValue } =
    useFormikContext() as any;
  const [invoiceRecipients, setInvoiceRecipients] = useState<string[]>(
    values.invoiceRecipients || []
  );
  const [addRecipient, setAddRecipient] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleDelete = (emailToDelete: any) => {
    setInvoiceRecipients(emails =>
      emails.filter(email => email !== emailToDelete)
    );
    setFieldValue(
      'invoiceRecipients',
      values?.invoiceRecipients.filter(
        (email: string) => email !== emailToDelete
      )
    );
  };

  const handleAdding = () => {
    // @ts-ignore
    if (!invoiceRecipients.includes(values.addRecipient)) {
      // @ts-ignore
      setInvoiceRecipients([...invoiceRecipients, addRecipient]);
      setFieldValue('invoiceRecipients', [...invoiceRecipients, addRecipient]);
      setAddRecipient('');
      setIsAdding(false);
    }
  };

  const validateField = async (value: string) => {
    try {
      await validationSchema.validateAt('addRecipient', {
        addRecipient: value,
      });
      setError('');
    } catch (validationError: any) {
      setError(validationError.message);
    }
  };

  const handleChange = (e: any) => {
    setAddRecipient(e.target.value);
    if (touched) {
      validateField(e.target.value);
    }
  };

  useEffect(() => {
    setInvoiceRecipients(values.invoiceRecipients || []);
  }, [values.invoiceRecipients]);

  return (
    <FieldArray
      name='invoiceRecipients'
      render={() => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              border: '1px solid rgba(255,255,255,0.2)',
              borderRadius: '4px',
              px: '16px',
              py: '10px',
              position: 'relative',
            }}>
            {isAdding && (
              <IconButton
                sx={{ position: 'absolute', top: 4, right: 4 }}
                onClick={() => setIsAdding(false)}>
                <ExpandLessTwoTone />
              </IconButton>
            )}
            <Box>
              <span
                style={{
                  marginBottom: '16px',
                  color: 'rgba(255,255,255,0.7)',
                  fontSize: '12px',
                  letterSpacing: '0.00938em',
                  lineHeight: '1.4375em',
                  fontWeight: 400,
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  position: 'absolute',
                  marginTop: '-20px',
                  marginLeft: '-8px',
                  backgroundColor: 'rgb(35 38 40)',
                  boxSizing: 'border-box',
                  padding: '0 6px',
                }}>
                {t('Invoice Recipients')}
              </span>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: isAdding ? 'wrap' : 'nowrap',
                  gap: 1,
                  pt: 0.25,
                  maxWidth: isAdding ? '100%' : '91.6%',
                  overflowX: 'hidden',
                  minHeight: '34px',
                }}>
                {!!invoiceRecipients &&
                  invoiceRecipients.length > 0 &&
                  invoiceRecipients
                    .filter((item: any, index: number) =>
                      isAdding || index < 2 ? item : null
                    )
                    .filter(Boolean)
                    .map((recipient: any, index: number) => (
                      <Chip
                        key={index}
                        label={recipient}
                        onDelete={() => handleDelete(recipient)}
                        deleteIcon={
                          isAdding ? (
                            <IconButton>
                              <CloseIcon />
                            </IconButton>
                          ) : (
                            <></>
                          )
                        }
                      />
                    ))}
                {!!invoiceRecipients &&
                invoiceRecipients.length > 2 &&
                !isAdding ? (
                  <Tooltip
                    title={invoiceRecipients
                      .slice(
                        invoiceRecipients.length - 3,
                        invoiceRecipients.length
                      )
                      .join(', ')}>
                    <span style={{ paddingTop: '6px' }}>
                      {t('… and')} {invoiceRecipients.length - 2} {t('more')}
                    </span>
                  </Tooltip>
                ) : !!invoiceRecipients &&
                  invoiceRecipients.length > 0 &&
                  !isAdding ? (
                  <></>
                ) : (
                  !isAdding && <>{t('billing_recipients_empty_list')}</>
                )}
                {!isAdding && (
                  <Button
                    variant='outlined'
                    color='primary'
                    size='small'
                    onClick={() => setIsAdding(true)}
                    sx={{
                      display: 'flex',
                      alignSelf: 'flex-end',
                      position: 'absolute',
                      right: '8px',
                    }}>
                    {t('Modify')}
                  </Button>
                )}
              </Box>
            </Box>
            {!!isAdding && (
              <Grid container xs={12}>
                <Grid xs={10}>
                  <Field
                    component={FormikTextField}
                    size='small'
                    fullWidth
                    type='email'
                    variant='outlined'
                    id='addRecipient'
                    name='addRecipient'
                    label='Add Invoice Recipient'
                    value={addRecipient}
                    onChange={handleChange}
                    onBlur={(e: any) => validateField(e.target.value)}
                    error={
                      touched.addRecipient &&
                      (Boolean(errors.addRecipient) || Boolean(error))
                    }
                    helperText={touched.addRecipient ? errors.addRecipient : ''}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleAdding();
                      }
                    }}
                  />
                </Grid>
                <Grid xs={2}>
                  <Button
                    disabled={
                      !addRecipient ||
                      Boolean(errors.addRecipient) ||
                      Boolean(error)
                    }
                    fullWidth
                    variant='text'
                    color='secondary'
                    size='small'
                    onClick={() => handleAdding()}
                    sx={{ height: '100%' }}>
                    {t('Add')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        );
      }}
    />
  );
};

export default InvoiceRecipients;

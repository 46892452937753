import FormatColorFillIcon from '@mui/icons-material/FormatColorFillRounded';
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import BoxedContainer from 'components/BoxedContainer';
import usePermissions from 'context/PermissionContext';
import {
  useDriverScorecardSettings,
  useUpdateDriverScorecardSettings,
} from 'hooks/drivers/useDrivers';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const HeaderItemStyled = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1A2027',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: 0,
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  minHeight: 60,
}));

const Item = styled(HeaderItemStyled)(({ theme }) => ({
  backgroundColor: '#191d22',
  minHeight: 90,
  padding: 0,
  paddingLeft: 6,
  paddingRight: 6,
  boxSizing: 'border-box',
}));

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 20,
    label: '20',
  },
];

function valuetext(value: number) {
  return `${value}c`;
}

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: '#3a8589',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
}));

interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> {}

const mapGradeToIndex = ['a', 'b', 'c', 'd', 'f'];

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      {mapGradeToIndex[(other as any)['data-index']]}
    </SliderThumb>
  );
}

const ThresholdSelector = () => {
  const { t } = useTranslation();
  const [threshold, setThreshold] = React.useState<number>(0);

  const handleChange = (event: any) => {
    setThreshold(parseInt(event.target.value));
  };

  return (
    <FormControl fullWidth>
      <InputLabel id='demo-simple-select-label'>{t('events')}</InputLabel>
      <Select
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        value={threshold.toString()}
        label='events'
        onChange={handleChange}>
        {Array(100)
          .fill(0)
          .map((_, i) => (
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

const ThresholdNumberInput = ({
  initialValue = 0,
  unitType = 'events',
  type = 'lt',
  disabled = false,
  style,
  onChange,
  min = 0,
  max = 100,
  step = 0.1,
}: {
  initialValue: number;
  unitType?: 'events' | 'seconds' | 'kilometers' | 'minutes';
  type?: 'lt' | 'gt' | 'min';
  disabled?: boolean;
  style?: any;
  onChange?: Function;
  min?: number;
  max?: number;
  step?: number;
}) => {
  const { t } = useTranslation();
  const { canWrite } = usePermissions();

  return (
    <TextField
      fullWidth={true}
      id='outlined-number'
      label={unitType}
      type='number'
      value={initialValue}
      disabled={disabled || !canWrite('DRIVER')}
      onChange={(e: any) => onChange && onChange(parseFloat(e.target.value))}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: type === 'min' && (
          <InputAdornment position='end'>
            <small
              style={{
                color: '#5a5a5a',
                textAlign: 'left',
                width: 'min-content',
                whiteSpace: 'break-spaces',
                marginLeft: 'auto',
                marginRight: 'auto',
                lineHeight: '16px',
              }}>
              {unitType === 'kilometers' ? 'km' : 'mins'}
            </small>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position='start'>
            {type === 'min' && (
              <small
                style={{
                  color: '#5a5a5a',
                  textAlign: 'left',
                  width: 'min-content',
                  whiteSpace: 'break-spaces',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  lineHeight: '16px',
                }}>
                {t('under')}
              </small>
            )}
            {type === 'lt' && (
              <small
                style={{
                  color: '#5a5a5a',
                  textAlign: 'left',
                  width: 'min-content',
                  whiteSpace: 'break-spaces',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  lineHeight: '16px',
                }}>
                {t('less than')}
              </small>
            )}
            {type === 'gt' && (
              <small
                style={{
                  color: '#5a5a5a',
                  textAlign: 'left',
                  width: 'min-content',
                  whiteSpace: 'break-spaces',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  lineHeight: '16px',
                }}>
                {t('greater than')}
              </small>
            )}
          </InputAdornment>
        ),
        inputProps: {
          min: min,
          max: max,
          step: step,
          style: { textAlign: 'center', ...style },
        },
      }}
    />
  );
};

interface GradesThresholdSetting {
  a: number;
  b: number;
  c: number;
  d: number;
}

interface GradesColorSetting {
  a: 'green' | 'amber' | 'red';
  b: 'green' | 'amber' | 'red';
  c: 'green' | 'amber' | 'red';
  d: 'green' | 'amber' | 'red';
  f: 'green' | 'amber' | 'red';
}

type GradeColorKeys = 'green' | 'amber' | 'red';

type Grades = 'a' | 'b' | 'c' | 'd' | 'f';

const AllGrades: Grades[] = ['a', 'b', 'c', 'd', 'f'];
const AllColors = ['green', 'amber', 'red'];

const GradeColors = {
  green: '#00b300',
  amber: '#ffbf00',
  red: '#FD6262',
};

const HeaderItem = ({
  children,
  gradeConfig,
  grade,
  onColorChange,
}: {
  children?: any;
  gradeConfig?: GradesColorSetting;
  grade?: Grades;
  onColorChange?: Function;
}) => {
  const [hover, setHover] = React.useState(false);

  const decideNextColor = (
    grade: Grades,
    gradeConfig?: GradesColorSetting,
    returnFinal?: boolean
  ) => {
    if (!gradeConfig || !grade) return;

    const index = AllGrades.indexOf(grade);
    const leftGrade: Grades | null =
      index - 1 >= 0 ? AllGrades[index - 1] : null; // can use "a" here as if a is green, it can still be all colors

    if (!leftGrade) {
      if (gradeConfig[grade] === 'green') {
        return returnFinal ? GradeColors['amber'] : 'amber';
      }
      if (gradeConfig[grade] === 'amber') {
        return returnFinal ? GradeColors['red'] : 'red';
      }
      if (gradeConfig[grade] === 'red') {
        return returnFinal ? GradeColors['green'] : 'green';
      }
    }
    if (leftGrade && gradeConfig[leftGrade] === 'green') {
      if (gradeConfig[grade] === 'green') {
        return returnFinal ? GradeColors['amber'] : 'amber';
      }
      if (gradeConfig[grade] === 'amber') {
        return returnFinal ? GradeColors['red'] : 'red';
      }
      if (gradeConfig[grade] === 'red') {
        return returnFinal ? GradeColors['green'] : 'green';
      }
    }
    if (leftGrade && gradeConfig[leftGrade] === 'amber') {
      if (gradeConfig[grade] === 'amber') {
        return returnFinal ? GradeColors['red'] : 'red';
      }
      if (gradeConfig[grade] === 'red') {
        return returnFinal ? GradeColors['amber'] : 'amber';
      }
    }
    if (leftGrade && gradeConfig[leftGrade] === 'red') {
      return returnFinal ? GradeColors['red'] : 'red';
    }
  };

  return (
    <HeaderItemStyled
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        fontSize: 24,
        backgroundColor:
          gradeConfig && grade && GradeColors[gradeConfig[grade]],
        position: 'relative',
      }}>
      {children}
      {gradeConfig && grade && hover && onColorChange && (
        <div style={{ position: 'absolute', right: 10 }}>
          <IconButton
            onClick={() =>
              onColorChange &&
              onColorChange(decideNextColor(grade, gradeConfig))
            }
            sx={{
              backgroundColor: decideNextColor(grade, gradeConfig, true),
            }}>
            <FormatColorFillIcon />
          </IconButton>
        </div>
      )}
    </HeaderItemStyled>
  );
};

const DriverBehavior = () => {
  const { t } = useTranslation();
  const { mutate: updateScoreCard } = useUpdateDriverScorecardSettings();
  const { data: scoreCardSettings, isLoading } =
    useDriverScorecardSettings() as any;
  const [per, setPer] = React.useState(
    JSON.stringify({ unit: 'km', per: 100 })
  );
  const [minThreshold, setMinThreshold] = React.useState(0);
  const { canWrite } = usePermissions();

  const [gradeConfig, setGradeConfig] = React.useState<GradesColorSetting>({
    a: 'green',
    b: 'green',
    c: 'amber',
    d: 'amber',
    f: 'red',
  });

  const updateGradeConfig = (grade: Grades, color: GradeColorKeys) => {
    const newGradeConfig = AllGrades.map((g, i) => {
      if (i < AllGrades.indexOf(grade)) {
        return gradeConfig[g];
      }
      if (i === AllGrades.indexOf(grade)) {
        return color;
      }
      if (AllColors.indexOf(color) >= AllColors.indexOf(gradeConfig[g])) {
        return color;
      }
      return gradeConfig[g];
    });

    setGradeConfig({
      a: newGradeConfig[0],
      b: newGradeConfig[1],
      c: newGradeConfig[2],
      d: newGradeConfig[3],
      f: newGradeConfig[4],
    });
  };

  const [harshAcceleration, setHarshAcceleration] =
    React.useState<GradesThresholdSetting>({ a: 1.0, b: 3.0, c: 4.0, d: 5.0 });

  const [harshBraking, setHarshBraking] =
    React.useState<GradesThresholdSetting>({ a: 1.0, b: 2.0, c: 3.0, d: 4.0 });

  const [harshCornering, setHarshCornering] =
    React.useState<GradesThresholdSetting>({ a: 1.0, b: 2.0, c: 3.0, d: 4.0 });

  const [speedingViolation, setSpeedingViolation] =
    React.useState<GradesThresholdSetting>({ a: 10, b: 30, c: 60, d: 120 });

  useEffect(() => {
    if (!!scoreCardSettings) {
      const { grades, thresholds } = scoreCardSettings;
      if (!thresholds) return;
      const { config, minimum } = thresholds;
      setGradeConfig(grades);
      setHarshAcceleration(thresholds.harshAcceleration);
      setHarshBraking(thresholds.harshBraking);
      setHarshCornering(thresholds.harshCornering);
      setSpeedingViolation(thresholds.speedingViolation);
      setPer(JSON.stringify(config));
      setMinThreshold(minimum?.value);
    }
  }, [scoreCardSettings]);

  useEffect(() => {
    if (!minThreshold && !!per) {
      setMinThreshold(
        JSON.parse(per).unit === 'km'
          ? JSON.parse(per).per / 4
          : (JSON.parse(per).per * 60) / 4
      );
    }
  }, [minThreshold, per]);

  const onSave = () => {
    const res = {
      grades: gradeConfig,
      thresholds: {
        minimum: {
          value: minThreshold,
          unit: JSON.parse(per).unit === 'km' ? 'km' : 'minutes',
        },
        config: JSON.parse(per),
        harshAcceleration,
        harshBraking,
        harshCornering,
        speedingViolation,
      },
    };
    updateScoreCard(res);
  };

  const validateThreshold = (
    values: GradesThresholdSetting,
    updater: Function
  ) => {
    const { a, b, c, d } = values;
    if (a === b) return false;
    if (b === c) return false;
    if (c === d) return false;

    updater(values);
    return true;
  };

  if (isLoading) return <></>;

  return (
    <BoxedContainer>
      {/* <h3>Driver Score Settings</h3> */}
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <HeaderItem></HeaderItem>
        </Grid>
        <Grid item xs={1.6}>
          <HeaderItem gradeConfig={gradeConfig} grade='a'>
            A
          </HeaderItem>
        </Grid>
        <Grid item xs={1.6}>
          <HeaderItem
            onColorChange={(color: GradeColorKeys) =>
              updateGradeConfig('b', color)
            }
            gradeConfig={gradeConfig}
            grade='b'>
            B
          </HeaderItem>
        </Grid>
        <Grid item xs={1.6}>
          <HeaderItem
            onColorChange={(color: GradeColorKeys) =>
              updateGradeConfig('c', color)
            }
            gradeConfig={gradeConfig}
            grade='c'>
            C
          </HeaderItem>
        </Grid>
        <Grid item xs={1.6}>
          <HeaderItem
            onColorChange={(color: GradeColorKeys) =>
              updateGradeConfig('d', color)
            }
            gradeConfig={gradeConfig}
            grade='d'>
            D
          </HeaderItem>
        </Grid>
        <Grid item xs={1.6}>
          <HeaderItem gradeConfig={gradeConfig} grade='f'>
            F
          </HeaderItem>
        </Grid>
        <Grid item xs={2}>
          <HeaderItem>
            <FormControl size='small'>
              <InputLabel id='per-select-label'>{t('per')}</InputLabel>
              <Select
                labelId='per-select-label'
                id='per-select'
                value={per}
                label='per'
                onChange={(e: any) => setPer(e.target.value)}>
                <MenuItem value={JSON.stringify({ unit: 'km', per: 100 })}>
                  {t('per 100km')}
                </MenuItem>
                <MenuItem value={JSON.stringify({ unit: 'km', per: 1000 })}>
                  {t('per 1000km')}
                </MenuItem>
                <MenuItem value={JSON.stringify({ unit: 'hour', per: 1 })}>
                  {t('per 1 hour')}
                </MenuItem>
                <MenuItem value={JSON.stringify({ unit: 'hour', per: 10 })}>
                  {t('per 10 hours')}
                </MenuItem>
              </Select>
            </FormControl>
          </HeaderItem>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Item>{t('Harsh Acceleration')}</Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              initialValue={harshAcceleration.a}
              style={{
                color: GradeColors[gradeConfig.a],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...harshAcceleration,
                    a: value,
                  },
                  setHarshAcceleration
                )
              }
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              initialValue={harshAcceleration.b}
              style={{
                color: GradeColors[gradeConfig.b],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...harshAcceleration,
                    b: value,
                  },
                  setHarshAcceleration
                )
              }
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              initialValue={harshAcceleration.c}
              style={{
                color: GradeColors[gradeConfig.c],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...harshAcceleration,
                    c: value,
                  },
                  setHarshAcceleration
                )
              }
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              initialValue={harshAcceleration.d}
              style={{
                color: GradeColors[gradeConfig.d],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...harshAcceleration,
                    d: value,
                  },
                  setHarshAcceleration
                )
              }
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              disabled={true}
              type={'gt'}
              initialValue={harshAcceleration.d}
              style={{
                color: GradeColors[gradeConfig.f],
              }}
            />
          </Item>
        </Grid>
        <Grid item xs={2}>
          <Item>
            {t('per')} {JSON.parse(per).per} {JSON.parse(per).unit}
          </Item>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 0.5, mb: 0.5 }} />
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Item>{t('Harsh Braking')}</Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              initialValue={harshBraking.a}
              style={{
                color: GradeColors[gradeConfig.a],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...harshBraking,
                    a: value,
                  },
                  setHarshBraking
                )
              }
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              initialValue={harshBraking.b}
              style={{
                color: GradeColors[gradeConfig.b],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...harshBraking,
                    b: value,
                  },
                  setHarshBraking
                )
              }
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              initialValue={harshBraking.c}
              style={{
                color: GradeColors[gradeConfig.c],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...harshBraking,
                    c: value,
                  },
                  setHarshBraking
                )
              }
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              initialValue={harshBraking.d}
              style={{
                color: GradeColors[gradeConfig.d],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...harshBraking,
                    d: value,
                  },
                  setHarshBraking
                )
              }
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              disabled={true}
              type={'gt'}
              initialValue={harshBraking.d}
              style={{
                color: GradeColors[gradeConfig.f],
              }}
            />
          </Item>
        </Grid>
        <Grid item xs={2}>
          <Item>
            {t('per')} {JSON.parse(per).per} {JSON.parse(per).unit}
          </Item>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 0.5, mb: 0.5 }} />
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Item>{t('Harsh Cornering')}</Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              initialValue={harshCornering.a}
              style={{
                color: GradeColors[gradeConfig.a],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...harshCornering,
                    a: value,
                  },
                  setHarshCornering
                )
              }
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              initialValue={harshCornering.b}
              style={{
                color: GradeColors[gradeConfig.b],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...harshCornering,
                    b: value,
                  },
                  setHarshCornering
                )
              }
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              initialValue={harshCornering.c}
              style={{
                color: GradeColors[gradeConfig.c],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...harshCornering,
                    c: value,
                  },
                  setHarshCornering
                )
              }
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              initialValue={harshCornering.d}
              style={{
                color: GradeColors[gradeConfig.d],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...harshCornering,
                    d: value,
                  },
                  setHarshCornering
                )
              }
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              disabled={true}
              type={'gt'}
              initialValue={harshCornering.d}
              style={{
                color: GradeColors[gradeConfig.f],
              }}
            />
          </Item>
        </Grid>
        <Grid item xs={2}>
          <Item>
            {t('per')} {JSON.parse(per).per} {JSON.parse(per).unit}
          </Item>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 0.5, mb: 0.5 }} />
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Item style={{ position: 'relative' }}>
            {t('Speeding Violation')}
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                color: '#5a5a5a',
                borderColor: '#5a5a5a',
                borderTop: '1px solid',
              }}>
              <small>{t('* measured in seconds')}</small>
            </div>
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              unitType={'seconds'}
              initialValue={speedingViolation.a}
              style={{
                color: GradeColors[gradeConfig.a],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...speedingViolation,
                    a: value,
                  },
                  setSpeedingViolation
                )
              }
              step={1}
              max={1000}
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              unitType={'seconds'}
              initialValue={speedingViolation.b}
              style={{
                color: GradeColors[gradeConfig.b],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...speedingViolation,
                    b: value,
                  },
                  setSpeedingViolation
                )
              }
              step={1}
              max={1000}
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              unitType={'seconds'}
              initialValue={speedingViolation.c}
              style={{
                color: GradeColors[gradeConfig.c],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...speedingViolation,
                    c: value,
                  },
                  setSpeedingViolation
                )
              }
              step={1}
              max={1000}
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              type={'lt'}
              unitType={'seconds'}
              initialValue={speedingViolation.d}
              style={{
                color: GradeColors[gradeConfig.d],
              }}
              onChange={(value: number) =>
                validateThreshold(
                  {
                    ...speedingViolation,
                    d: value,
                  },
                  setSpeedingViolation
                )
              }
              step={1}
              max={1000}
            />
          </Item>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              disabled={true}
              unitType={'seconds'}
              type={'gt'}
              initialValue={speedingViolation.d}
              style={{
                color: GradeColors[gradeConfig.f],
              }}
              step={1}
              max={1000}
            />
          </Item>
        </Grid>
        <Grid item xs={2}>
          <Item>
            {t('per')} {JSON.parse(per).per} {JSON.parse(per).unit}
          </Item>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 0.5, mb: 0.5 }} />
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Tooltip
            placement={'bottom'}
            arrow
            title={t(
              'driver_behavior_little_data_skipped_calculation_disclaimer'
            )}>
            <Item>
              <div>{t('Minimum Threshold')}</div>
            </Item>
          </Tooltip>
        </Grid>
        <Grid item xs={1.6}>
          <Item>
            <ThresholdNumberInput
              unitType={
                JSON.parse(per).unit === 'km' ? 'kilometers' : 'minutes'
              }
              type={'min'}
              initialValue={minThreshold}
              step={5}
              max={
                JSON.parse(per).unit === 'km'
                  ? JSON.parse(per).per
                  : JSON.parse(per).per * 60
              }
              onChange={(value: number) => setMinThreshold(value)}
            />
          </Item>
        </Grid>
        <Grid item xs={8.4}>
          <Item />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 0.5, mb: 0.5 }} />
      <Grid container spacing={0}>
        <Grid item xs={10} />
        <Grid
          item
          xs={2}
          style={{
            backgroundColor: 'transparent',
            textAlign: 'center',
            display: 'flex',
          }}>
          <Grid xs={6}>
            <Button color='error' variant='text' fullWidth={true}>
              {t('Reset')}
            </Button>
          </Grid>
          <Grid xs={6}>
            <Button
              disabled={!canWrite('DRIVER')}
              color='success'
              variant='outlined'
              fullWidth={true}
              onClick={() => onSave()}>
              {t('Save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </BoxedContainer>
  );
};

export default DriverBehavior;

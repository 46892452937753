import { Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import useActionMenuContext, {
  ActionMenuModuleTypes,
} from 'context/ActionMenuContext';
import useUIContext from 'context/UIContext';
import useCmdKeyPress from 'hooks/useCmdKeyPress';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActivityFeedActionMenu from './modules/ActivityFeed/index';

const AddLocationActionMenu = lazy(
  () => import(`./modules/Location/AddLocation`)
);
const AddUserActionMenu = lazy(() => import(`./modules/AddUser`));
const AddRoleActionMenu = lazy(() => import(`./modules/AddRole`));
const AddAssetActionMenu = lazy(() => import(`./modules/Asset/AddAsset`));
const AddDriverActionMenu = lazy(() => import(`./modules/AddDriver`));
const AddGroupActionMenu = lazy(() => import(`./modules/AddGroup`));
const GlobalSearch = lazy(() => import(`./modules/GlobalSearch`));
const LocationBulkImportActionMenu = lazy(
  () => import(`./modules/Location/ImportLocation`)
);
const ListAssetsActionMenu = lazy(() => import(`./modules/List/ListAssets`));
const EditAssetActionMenu = lazy(() => import(`./modules/Asset/AddAsset`));
const IntegrationsActionMenu = lazy(() => import('./modules/Integrations'));
const AddContactActionMenu = lazy(() => import('./modules/Contact/AddContact'));
const AddNotificationActionMenu = lazy(
  () => import('./modules/Notification/AddNotification')
);
const UpsertAISRegion = lazy(() => import('./modules/UpsertAISRegion'));

const useStyles: any = makeStyles(() =>
  createStyles({
    expandedSideBar: {
      opacity: 1,
    },
    collapsedSideBar: {
      opacity: 0,
    },
  })
);

const ActionMenu = ({
  preventKeyPressDefault,
}: {
  preventKeyPressDefault?: boolean;
}) => {
  const { t } = useTranslation();
  const { active, reset, setActive, extra } = useActionMenuContext();
  const {
    rightSideBarOpen,
    fixRightSidebar,
    openRightSidebar,
    rightSideBarFixed,
    closeRightSidebar,
    isMobile,
  } = useUIContext();
  const [animationComplete, setAnimationComplete] = useState(false);
  const [expandGlobalSearch, setExpandGlobalSearch] = useState(false);
  const [activateSearch, setActivateSearch] = useState(false);
  const [activityLogFilters, setActivityLogFilters] = useState({});
  const onSearchCmdPress = useCmdKeyPress('f', preventKeyPressDefault);

  const classes = useStyles();

  useEffect(() => {
    if (rightSideBarOpen) {
      setTimeout(() => {
        setAnimationComplete(true);
      }, 220);
    } else {
      setAnimationComplete(false);
      setActivateSearch(false);
    }
  }, [rightSideBarOpen]);

  useEffect(() => {
    if (rightSideBarFixed) return;
    setActivateSearch(false);
  }, [rightSideBarFixed]);

  useEffect(() => {
    if (ActionMenuModuleTypes.Default === active) {
      fixRightSidebar(false);
    } else {
      fixRightSidebar(true);
    }
  }, [active]);

  useEffect(() => {
    if (!onSearchCmdPress) return;
    setActivateSearch(true);
  }, [onSearchCmdPress]);

  useEffect(() => {
    if (!activateSearch) return;
    openRightSidebar();
  }, [activateSearch]);

  if (!rightSideBarOpen) return <div></div>;

  const getModuleTitle: any = (active: any) => {
    return [ActionMenuModuleTypes.ListAssets].indexOf(active) >= 0
      ? ''
      : t(active);
  };

  // TODO: ActionMenuModule creation and setup needs to be improved
  return (
    <div
      className={[
        'right-side-bar',
        rightSideBarOpen ? classes.expandedSideBar : classes.collapsedSideBar,
      ].join(' ')}
      style={{
        minWidth: isMobile ? '100%' : '400px',
        ...(isMobile && {
          maxWidth: '100%',
          overflow: 'hidden',
        }),
        padding: isMobile ? '1rem 0.5rem 0rem 0.5rem' : '1rem 1rem 0 1rem',
        boxSizing: 'border-box',
        height: '100vh',
        overflowX: 'hidden',
        overflowY: 'scroll',
        transition: '.2s opacity linear',
        backgroundColor: '#242628',
      }}>
      <Box sx={{ typography: 'title1', fontSize: 'h6.fontSize' }}>
        {getModuleTitle(active)}
      </Box>
      <Suspense fallback={<div>{t('Loading…')}</div>}>
        {ActionMenuModuleTypes.Default === active && (
          <>
            <GlobalSearch
              focusOnMount={activateSearch}
              activityLogFilters={activityLogFilters}
              setActivityLogFilters={setActivityLogFilters}
              onSearchInputBlur={() => setActivateSearch(false)}
              expand={(shouldExpand: boolean) => {
                setExpandGlobalSearch(shouldExpand);
                if (shouldExpand) {
                  setActivityLogFilters({});
                }
              }}
            />
            {!expandGlobalSearch ? (
              <ActivityFeedActionMenu extra={{ filters: activityLogFilters }} />
            ) : (
              ''
            )}
          </>
        )}
        {ActionMenuModuleTypes.AddLocation === active && (
          <AddLocationActionMenu onRemove={() => reset()} />
        )}
        {ActionMenuModuleTypes.EditLocation === active && (
          <AddLocationActionMenu locationId={extra.locationId} />
        )}
        {ActionMenuModuleTypes.AddAsset === active && <AddAssetActionMenu />}
        {ActionMenuModuleTypes.EditAsset === active && (
          <AddAssetActionMenu assetId={extra.assetId} />
        )}
        {ActionMenuModuleTypes.AddDriver === active && <AddDriverActionMenu />}
        {ActionMenuModuleTypes.EditDriver === active && (
          <AddDriverActionMenu driverId={extra.driverId} />
        )}
        {ActionMenuModuleTypes.AddGroup === active && <AddGroupActionMenu />}
        {ActionMenuModuleTypes.EditGroup === active && (
          <AddGroupActionMenu groupId={extra.groupId} />
        )}
        {ActionMenuModuleTypes.AddUser === active && <AddUserActionMenu />}
        {ActionMenuModuleTypes.EditUser === active && (
          <AddUserActionMenu userId={extra.userId} />
        )}
        {ActionMenuModuleTypes.AddRole === active && (
          <AddRoleActionMenu roleId={extra?.roleId} />
        )}
        {ActionMenuModuleTypes.ImportLocation === active && (
          <LocationBulkImportActionMenu />
        )}
        {ActionMenuModuleTypes.ListAssets === active && (
          <ListAssetsActionMenu extra={extra} />
        )}
        {ActionMenuModuleTypes.AddIntegration === active && (
          <IntegrationsActionMenu extra={extra} />
        )}
        {ActionMenuModuleTypes.AddContact === active && (
          <AddContactActionMenu />
        )}
        {ActionMenuModuleTypes.EditContact === active && (
          <AddContactActionMenu contactId={extra?.contactId} />
        )}
        {ActionMenuModuleTypes.AddNotificationSetting === active && (
          <AddNotificationActionMenu />
        )}
        {ActionMenuModuleTypes.EditNotificationSetting === active && (
          <AddNotificationActionMenu notificationId={extra?.notificationId} />
        )}
        {ActionMenuModuleTypes.UpsertAISRegion === active && (
          <UpsertAISRegion />
        )}
      </Suspense>
    </div>
  );
};

export default ActionMenu;

import { DataGrid } from '@mui/x-data-grid/DataGrid';
import React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import DownloadIcon from '@mui/icons-material/Download';
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import UtilizationLineChart from 'components/Reports/Widgets/UtilizationLineChart';
import useActionMenuContext from 'context/ActionMenuContext';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import {
  useAssetsUtilization,
  useExportUtilizationReport,
} from 'hooks/assets/useAssets';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import ReportWrapper from '../ReportWrapper';
import AssetUtilizationToolbarWithUrlFilters from './AssetUtilizationFilters';

const commonColDefOptions = {
  resizable: false,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
  filtrable: false,
  hideable: false,
};

const formatHours = function (hours: number) {
  if (hours < 1) return `${(hours * 60).toFixed(0)} minutes`;
  return `${hours} hours`;
};

function getColumns({ t }: any): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'assetName',
      headerName: t('Asset'),
      ...commonColDefOptions,
      flex: 1,
    }, // @ts-ignore
    {
      field: 'formattedAddress',
      headerName: t('Last Reported Location'),
      ...commonColDefOptions,
      flex: 1,
    }, // @ts-ignore
    {
      field: 'utilization.expected',
      headerName: t('Expected Utilization'),
      ...commonColDefOptions,
      flex: 0.75,
      renderCell: (r: any) => {
        if (r?.row?.utilization?.expected === 0) return '';
        return `${r?.row?.utilization?.expected} hours`;
      },
    }, // @ts-ignore
    {
      field: 'utilization.actual',
      headerName: t('Total Running Time'),
      ...commonColDefOptions,
      flex: 0.75,
      renderCell: (r: any) => {
        return `${formatHours(
          (r?.row?.utilization?.actual + r?.row?.utilization?.idling).toFixed(2)
        )}`;
      },
    }, // @ts-ignore
    {
      field: 'utilization.idling',
      headerName: t('Idling Time'),
      ...commonColDefOptions,
      flex: 0.75,
      renderCell: (r: any) => {
        return `${formatHours(r?.row?.utilization?.idling.toFixed(2))}`;
      },
    }, // @ts-ignore
    {
      field: 'utilization.utilization',
      headerName: t('Utilization'),
      ...commonColDefOptions,
      flex: 0.75,
      renderCell: (r: any) => {
        return `${formatHours(
          parseFloat(
            (r?.row?.utilization?.actual - r?.row?.utilization?.idling).toFixed(
              2
            )
          )
        )}`;
      },
    }, // @ts-ignore
    {
      field: 'utilization.utilization.pct',
      headerName: t('Utilization %'),
      ...commonColDefOptions,
      flex: 0.75,
      renderCell: (r: any) => {
        if (r?.row?.utilization?.expected === 0) return '';
        const percent =
          (r?.row?.utilization?.actual / r?.row?.utilization?.expected) * 100;
        return `${percent ? percent.toFixed(2) : 0}%`;
      },
    }, // @ts-ignore
  ];
}

const ExportAssetLog = ({ filters }: any) => {
  const { t } = useTranslation();
  const { setFlashMessage } = useUIContext();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    data: exportData,
    mutate: exportReport,
    error: exportError,
    reset: exportReset,
    isLoading: isExporting,
    isSuccess: exportSuccess,
  } = useExportUtilizationReport();

  const handleExport = (fileType: any) => {
    handleClose();
    exportReport({ ...filters, fileType });
  };

  React.useEffect(() => {
    if (exportData && !exportData.exportUrl) {
      setFlashMessage({ message: t(`reports.${exportData.message}`) });
    }
  }, [exportData]);

  return (
    <>
      <Tooltip arrow={true} title={t('reports.export_report')}>
        <IconButton disabled={isExporting} onClick={handleClick}>
          {isExporting ? (
            <CircularProgress size={24} sx={{ mr: 0.25 }} />
          ) : (
            <DownloadIcon />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('excel')}>
              <ListItemText
                primary='.xlsx'
                primaryTypographyProps={{ fontSize: '.85rem' }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('pdf')}>
              <ListItemText
                primaryTypographyProps={{ fontSize: '.85rem' }}
                primary='.pdf'
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      {exportSuccess && exportData && exportData.reportUrl && (
        <iframe
          width='1pxre'
          height='1px'
          style={{ visibility: 'hidden', position: 'absolute' }}
          referrerPolicy='origin'
          src={exportData.reportUrl}></iframe>
      )}
    </>
  );
};

const AssetUtilization = ({ assetType }: any) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [searchParams]: any = useComplexSearchParams();

  const { data, isLoading } = useAssetsUtilization({
    page: page + 1,
    filterBy: searchParams,
  }) as any;
  const { reset } = useActionMenuContext();
  const { canWrite, canRead } = usePermissions();

  React.useEffect(() => {
    return () => reset();
  }, []);

  React.useEffect(() => {
    setRowCountState(data?.numRecords ?? 0);
  }, [data?.numRecords, setRowCountState]);

  return (
    <ReportWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Box sx={{ width: '100%' }}>
          <AssetUtilizationToolbarWithUrlFilters
            type={'utilization'}
            assetType={assetType}
            exportComponent={ExportAssetLog}
          />
        </Box>
      </Box>
      <Box
        className='widget-container'
        key={1}
        sx={{ mb: 2, minHeight: '200px' }}>
        <div className='widget-content'>
          <UtilizationLineChart
            assetType='vehicle'
            widgetType='utilization'
            chartUnit='%'
            showTotal={false}
            startTimestamp={searchParams?.startTimestamp}
            endTimestamp={searchParams?.endTimestamp}
            groupIds={searchParams?.groupIds}
          />
        </div>
      </Box>
      <DataGrid
        rows={data && data.reports ? data.reports : []}
        getRowId={row => row.assetId}
        columns={getColumns({ t })}
        pageSize={10}
        checkboxSelection={false}
        autoHeight
        loading={isLoading}
        rowHeight={40}
        rowsPerPageOptions={[10]}
        rowCount={rowCountState}
        onPageChange={newPage => setPage(newPage)}
        paginationMode='server'
        pagination
      />
      <div style={{ marginTop: '0.1em', lineHeight: '1em' }}>
        <Typography variant={'caption'} sx={{ color: 'rgba(255,255,255,0.5)' }}>
          1. {t('expected_utilization_config_settings_info')}
        </Typography>
      </div>
      <div style={{ marginTop: '0.1em', lineHeight: '1em' }}>
        <Typography variant={'caption'} sx={{ color: 'rgba(255,255,255,0.5)' }}>
          2. {t('expected_utilization_calculation_explainer')}
        </Typography>
      </div>
    </ReportWrapper>
  );
};

export default AssetUtilization;

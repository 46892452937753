import { Delete } from '@mui/icons-material';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import EditIcon from '@mui/icons-material/Edit';
import SpeedIcon from '@mui/icons-material/Speed';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box/Box';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import BoxedContainer from 'components/BoxedContainer';
import Table from 'components/Table';
import useActionMenuContext, {
  ActionMenuModuleTypes,
} from 'context/ActionMenuContext';
import useUIContext from 'context/UIContext';
import { useDeleteLocation, useExportReport, useLocations } from 'hooks';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import { ReactCountryFlag } from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { useLocation as useRouterLocation } from 'react-router-dom';
import ConfirmDeleteDialog from '../../../components/SystemDialogs/ConfirmDeleteDialog';
import usePermissions from '../../../context/PermissionContext';
import LocationsToolbar from './LocationsToolbar';

const getCountryISO2 = require('country-iso-3-to-2');
const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });

const commonColDefOptions: Partial<GridColDef> = {
  // resizable: true,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
};

function getColumns({
  handleEditLocation,
  handleDeleteLocation,
  isDeleting,
  canWrite,
  t,
}: any): GridColDef[] {
  return [
    // @ts-ignore
    { field: 'name', headerName: t('Name'), ...commonColDefOptions }, // @ts-ignore
    {
      field: 'description',
      headerName: t('Description'),
      ...commonColDefOptions,
      sortable: false,
    }, // @ts-ignore
    {
      field: 'countryCode',
      headerName: t('Country'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return r?.row?.formattedAddress ? (
          <Tooltip arrow={true} title={r?.row?.formattedAddress}>
            <Box>
              <ReactCountryFlag countryCode={getCountryISO2(r?.value)} />
              <Box sx={{ ml: 1, display: 'inline' }}>
                {regionNamesInEnglish.of(getCountryISO2(r?.value))}
              </Box>
            </Box>
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    }, // @ts-ignore
    {
      field: 'coordinates',
      headerName: t('Coordinates'),
      ...commonColDefOptions,
      sortable: false,
      renderCell: (r: any) => {
        return `${r?.row?.position?.lat?.toFixed(
          5
        )}, ${r?.row?.position?.lon?.toFixed(5)}`;
      },
    }, // @ts-ignore
    {
      field: 'groupId',
      headerName: t('Group'),
      ...commonColDefOptions,
      sortable: false,
      valueGetter: ({ row }) => {
        return row.group?.name;
      },
    }, // @ts-ignore
    {
      field: 'createdBy',
      headerName: t('Added by'),
      ...commonColDefOptions,
      sortable: false,
      valueGetter: ({ row }) => {
        return row.createdBy?.name;
      },
    }, // @ts-ignore
    {
      field: 'null',
      headerName: '',
      ...commonColDefOptions,
      sortable: false,
      flex: 0.7,
      align: 'center',
      filterable: false,
      renderCell: (r: any) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {r?.row?.entryAlert && (
              <Tooltip title={t('Entry alert')}>
                <CenterFocusStrongIcon
                  sx={{
                    fontSize: '1.4em',
                    mx: 0.5,
                    color: 'rgba(255,255,255,0.75)',
                  }}
                />
              </Tooltip>
            )}
            {r?.row?.exitAlert && (
              <Tooltip title={t('Exit alert')}>
                <CenterFocusWeakIcon
                  sx={{
                    fontSize: '1.4em',
                    mx: 0.5,
                    color: 'rgba(255,255,255,0.75)',
                  }}
                />
              </Tooltip>
            )}
            {r?.row?.speedingThreshold && r?.row?.speedingThreshold > 0 ? (
              <Tooltip
                title={
                  <>
                    {t('Local Speed Limit:')}
                    {` ${r?.row?.speedingThreshold} km/h`}
                  </>
                }>
                <SpeedIcon
                  sx={{
                    fontSize: '1.4em',
                    mx: 0.5,
                    color: 'rgba(255,255,255,0.75)',
                  }}
                />
              </Tooltip>
            ) : (
              ''
            )}
          </Box>
        );
      },
    }, // @ts-ignore
    ...(canWrite('LOCATION')
      ? [
          {
            ...commonColDefOptions,
            sortable: false,
            align: 'center',
            headerName: '',
            minWidth: 90,
            maxWidth: 100,
            renderCell: (r: any) => {
              return (
                <div>
                  <Tooltip title={t('Edit location')}>
                    <IconButton
                      disabled={isDeleting}
                      onClick={() => handleEditLocation(r?.row?.locationId)}>
                      <EditIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('Delete location')}>
                    <IconButton
                      disabled={isDeleting}
                      onClick={() => handleDeleteLocation(r?.row)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </div>
              );
            },
          },
        ]
      : []),
  ];
}

const Locations = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { mutate, isLoading: isDeleting } = useDeleteLocation();
  const { openRightSidebar } = useUIContext();
  const { setActive, reset } = useActionMenuContext();
  const location: any = useRouterLocation();
  const {
    data: exportData,
    mutate: exportReport,
    error: exportError,
    reset: exportReset,
    isLoading: isExporting,
    isSuccess: exportSuccess,
  } = useExportReport();
  const [deleteLocation, setDeleteLocation] = useState<any>(null);
  const { canWrite } = usePermissions();
  const [page, setPage] = React.useState(0);
  const pageSize = 10;
  const [rowCountState, setRowCountState] = React.useState(0);
  const [searchParams] = useComplexSearchParams();
  const { data: locationData, isLoading } = useLocations({
    searchTerm,
    page: page + 1,
    pageSize,
    searchParams,
  }) as any;

  const handleImportLocation = () => {
    setActive(ActionMenuModuleTypes.ImportLocation);
    openRightSidebar();
  };

  const handleEditLocation = (locationId: number) => {
    setActive(ActionMenuModuleTypes.EditLocation, { locationId });
    openRightSidebar();
  };

  const handleDeleteLocation = (location: any) => {
    setDeleteLocation(location);
  };

  useEffect(() => {
    if (location?.state?.actionMenu) {
      if (
        location?.state?.actionMenu === ActionMenuModuleTypes.ImportLocation
      ) {
        handleImportLocation();
      }
    }
  }, [location.state]);

  React.useEffect(() => {
    setRowCountState(locationData?.numRecords ?? 0);
  }, [locationData?.numRecords, setRowCountState]);

  return (
    <BoxedContainer>
      <LocationsToolbar
        setSearchTerm={setSearchTerm}
        isExporting={isExporting}
        handleImportLocation={handleImportLocation}
      />
      <Table
        data={locationData && locationData.locations}
        getRowId={(row: any) => row.locationId}
        isLoading={isLoading}
        columns={getColumns({
          handleEditLocation,
          handleDeleteLocation,
          isDeleting,
          canWrite,
          t,
        })}
        disableColumnFilter={true}
        disableColumnMenu={true}
        pageSize={12}
        rowCount={rowCountState}
        onPageChange={newPage => setPage(newPage)}
        paginationMode='server'
      />
      <ConfirmDeleteDialog
        open={!!deleteLocation}
        onCancel={() => {
          setDeleteLocation(null);
        }}
        onConfirm={() => {
          if (deleteLocation) mutate({ id: deleteLocation?.locationId });
        }}
        title={t('Confirm to delete location')}
        content={
          <>
            {t('Are you sure you want to delete')} <b>{deleteLocation?.name}</b>
            ?
          </>
        }
      />
    </BoxedContainer>
  );
};

export default Locations;

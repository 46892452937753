import React from 'react';
import { CloudOffRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const getOnLineStatus = () =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;

const useNavigatorOnLine = () => {
  const [status, setStatus] = React.useState(getOnLineStatus());

  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  React.useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return status;
};

export const Connectivity = () => {
  const { t } = useTranslation();
  const isOnline = useNavigatorOnLine();
  const [lastState, setLastState] = React.useState<boolean>(isOnline);

  React.useEffect(() => {
    if (!lastState && isOnline) {
      window.location.reload();
      return;
    }
    setLastState(isOnline);
  }, [isOnline]);

  if (isOnline) return <></>;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        padding: 6,
        textAlign: 'center',
        backgroundColor: '#ffbf00',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        color: 'black',
      }}>
      <CloudOffRounded />
      &nbsp;&nbsp;{t(`it_looks_like_youre_offline`)}
    </div>
  );
};

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import area from '@turf/area';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import buffer from '@turf/buffer';
import center from '@turf/center';
import { lineString } from '@turf/helpers';
import TripEndImage from 'assets/trip-end.svg';
import TripPointImage from 'assets/trip-point.svg';
import deviceTypes from 'common-web/definitions/deviceTypes.json';
import { AssetType } from 'common-web/types/asset/AssetType';
import { convertUnitForAssetType, getUnitForAssetType } from 'common-web/utils';
import BoxedContainer from 'components/BoxedContainer';
import {
  LAYERS,
  LineStringLayer,
  LineStringSource,
  Point,
  PointSource,
  SOURCES,
} from 'components/Map';
import Map from 'components/Map/AltMap';
import ContainerLineChart from 'components/Reports/Widgets/ContainerLineChart';
import useUIContext from 'context/UIContext';
import {
  useAllTripReports,
  useTrip,
  useTripLocations,
} from 'hooks/reports/useTrips';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useInfiniteDirectionData } from 'hooks/useMapbox';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TripReports from './TripReports';
import { useTranslation } from 'react-i18next';

function getDeviceFullName(id: any) {
  if (deviceTypes) {
    var config = deviceTypes.deviceTypes.filter((d: any) => d.id === id);
    return config?.[0]?.manufacturer + ' ' + config?.[0]?.model;
  }
}

const MyTableRow = ({ label, value, labelStyle, valueStyle, onClick }: any) => {
  return (
    <TableRow>
      <TableCell
        style={{ ...labelStyle }}
        sx={{
          border: 0,
          fontSize: '0.8rem',
          fontWeight: 'bold',
          color: '#ccc',
        }}>
        {label}
      </TableCell>
      <TableCell
        onClick={onClick}
        style={{ ...valueStyle }}
        sx={{ border: 0, fontSize: '0.8rem' }}>
        {value}
      </TableCell>
    </TableRow>
  );
};

const tripPointImage: any = {
  id: 'trip-point',
  src: TripPointImage,
  type: 'svg',
};
const tripEndImage: any = { id: 'trip-end', src: TripEndImage, type: 'svg' };
const tripDetailPointLayer: any = Point({
  id: LAYERS.TRIP_DETAIL_POINT,
  source: SOURCES.TRIP_DETAIL_POINT,
  icon: 'trip-point',
  size: 0.325,
  anchor: 'bottom',
});
const tripEndPointLayer: any = Point({
  id: LAYERS.TRIP_END_POINT,
  source: SOURCES.TRIP_END_POINT,
  icon: 'trip-end',
  size: 0.325,
  anchor: 'bottom-left',
});
const vehicleTripMapMatchedLayer: any = LineStringLayer({
  id: LAYERS.TRIP_LINE_MAP_MATCHED,
  source: SOURCES.TRIP_LINE_MAP_MATCHED,
  size: 3,
});

const Trip = () => {
  const { t } = useTranslation();
  let { id: tripId } = useParams();
  const { data } = useTrip(tripId) as any;
  const canMapMatch =
    (['vehicle', 'trailer', 'container'].includes(data?.assetType) &&
      [
        'orbcomm_ct1000',
        'orbcomm_sc1000',
        'orbcomm_idp680',
        'orbcomm_idp800',
        'orbcomm_st6100',
      ].includes(data?.deviceType)) ??
    false;
  const { data: tripReportData, isLoading: isTripDataLoading } =
    useTripLocations(tripId, !canMapMatch) as any;
  const { data: allTripData, isLoading: isAllTripDataLoading } =
    useAllTripReports(tripId, !!canMapMatch) as any;
  const [locationBbox, setLocationBbox] = React.useState<any>(null);
  const [mapCenter, setCenter] = React.useState<any>(null);
  const [lineStringSource, setLineStringSource] = React.useState<any>();
  const [tripEndPointSource, setTripEndPointSource] = React.useState<any>();
  const [detailTripPoint, setDetailTripPoint] = React.useState<any>();
  const [detailTripPointSource, setDetailTripPointSource] =
    React.useState<any>();
  const [flyTo, setFlyTo] = React.useState<any>();
  const navigate = useNavigate();
  const [storedMapStyle, storeMapStyle] = useLocalStorage('mapStyle', '');
  const [playbackOpen, setPlaybackOpen] = React.useState(false);
  const { isMobile } = useUIContext();
  const [shouldMapMatch, setShouldMapMatch] = React.useState(false);
  const [mapMatchedlineStringSource, setMapMatchedLineStringSource] =
    React.useState<any>();

  const vehicleTripLayer: any = LineStringLayer({
    id: LAYERS.TRIP_VEHICLE_LINE,
    source: SOURCES.LINE_STRING,
    size: 3,
    ...(!!canMapMatch &&
      shouldMapMatch && {
        dash: [2, 2],
        color: '#71c6ff',
        size: 2,
        opacity: 0.8,
      }),
    metadata: {
      rerender: shouldMapMatch,
    },
  });

  const tripLocationData = useMemo(() => {
    if (!!tripReportData && tripReportData?.length > 0) {
      return {
        reports: tripReportData,
      };
    }
    return allTripData ?? tripReportData;
  }, [tripReportData, allTripData]);

  const {
    data: directionData,
    fetchNextPage: fetchNextRoutePage,
    hasNextPage: hasNextRoutePage,
    isFetchingNextPage: isFetchingNextRoutePage,
  } = useInfiniteDirectionData(
    process.env.REACT_APP_MAPBOX_KEY!,
    tripLocationData?.reports?.map((report: any) => [
      report?.position?.lon,
      report?.position?.lat,
    ]),
    shouldMapMatch && tripLocationData?.reports?.length > 0 && !!canMapMatch
  ) as any;

  const isMapMatchable = useMemo(() => {
    if (!!data) {
    }
  }, [data]);

  useEffect(() => {
    if (!!hasNextRoutePage && !isFetchingNextRoutePage) {
      fetchNextRoutePage();
    }
  }, [hasNextRoutePage, isFetchingNextRoutePage]);

  useEffect(() => {
    if (directionData?.pages?.length > 0 && !!canMapMatch) {
      const combinedDirectionData = directionData?.pages?.reduce(
        (acc: any, page: any) => {
          const route = page?.routes?.[0];
          if (!route) return acc;

          const distance = route.distance;
          const duration = route.duration;
          const routes = route.geometry.coordinates;
          return {
            waypoints: [
              ...acc.waypoints,
              ...page.waypoints.map((waypoint: any) => ({
                distance: waypoint.distance,
                location: waypoint.location,
                name: waypoint.name,
              })),
            ],
            routes: [
              {
                ...acc?.routes?.[0],
                distance: acc.distance ? acc.distance + distance : distance,
                duration: acc.duration ? acc.duration + duration : duration,
                geometry: {
                  type: 'LineString',
                  coordinates: acc?.routes?.[0]?.geometry?.coordinates
                    ? acc?.routes?.[0].geometry?.coordinates.concat(routes)
                    : routes,
                },
              },
            ],
          };
        }
      );
      const mapDirectionData =
        combinedDirectionData?.routes?.[0]?.geometry.coordinates;
      setMapMatchedLineStringSource(
        LineStringSource(
          mapDirectionData?.map((coord: any) => ({
            position: {
              lon: coord[0],
              lat: coord[1],
            },
          })),
          SOURCES.TRIP_LINE_MAP_MATCHED
        )
      );
    }
  }, [directionData]);

  useEffect(() => {
    if (tripLocationData) {
      const locationMapped = tripLocationData?.reports?.map(
        ({ position }: any) => [
          parseFloat(position.lon),
          parseFloat(position.lat),
        ]
      );
      if (!locationMapped || locationMapped?.length === 0) return;

      const locationLineString = lineString(locationMapped);
      const tmpBbox = bboxPolygon(bbox(locationLineString));

      const locationBbox = bboxPolygon(
        bbox(
          buffer(
            locationLineString.geometry,
            Math.max(0.5, area(tmpBbox) / 1_000_000 / 1000),
            { units: 'kilometers' }
          )
        )
      );
      setLocationBbox(locationBbox);
      setCenter(center(locationBbox));
    }
  }, [tripLocationData]);

  React.useEffect(() => {
    if (!mapCenter) return;
    if (!detailTripPoint) {
      setDetailTripPointSource(PointSource(SOURCES.TRIP_DETAIL_POINT, null));
      setFlyTo({
        center: mapCenter?.geometry?.coordinates,
        zoom: 1,
        essential: true,
      });
      return;
    }

    setDetailTripPointSource(
      PointSource(SOURCES.TRIP_DETAIL_POINT, detailTripPoint)
    );
    const { lat, lon } = detailTripPoint?.position;
    if (lat && lon) setFlyTo({ center: [lon, lat], zoom: 16, essential: true });
  }, [detailTripPoint]);

  React.useEffect(() => {
    if (tripLocationData && tripLocationData?.reports?.length > 0) {
      setLineStringSource(LineStringSource(tripLocationData?.reports));
      setTripEndPointSource(
        PointSource(
          SOURCES.TRIP_END_POINT,
          tripLocationData?.reports[tripLocationData?.reports?.length - 1]
        )
      );
    }
  }, [tripLocationData]);

  return (
    <BoxedContainer
      style={{ ...(isMobile && { width: 'auto', paddingBottom: '200px' }) }}>
      <Box sx={{ backgroundColor: 'transparent' }}>
        <Grid container spacing={0} sx={{ py: 0.5, pb: 0 }}>
          <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
            {(!data || !tripLocationData) && (
              <Skeleton
                variant='rounded'
                width={'100%'}
                height={240}
                sx={{ transform: 'none' }}
              />
            )}
            {data && tripLocationData && mapCenter && !playbackOpen && (
              <>
                <Map
                  center={mapCenter?.geometry?.coordinates}
                  flyTo={flyTo}
                  hasControls={false}
                  hasDraw={false}
                  images={[tripEndImage, tripPointImage]}
                  layers={[
                    ...(!!canMapMatch && shouldMapMatch
                      ? [vehicleTripMapMatchedLayer]
                      : []),
                    vehicleTripLayer,
                    // assetTripPointLayer,
                    tripEndPointLayer,
                    tripDetailPointLayer,
                  ]}
                  sources={[
                    ...(!!canMapMatch && shouldMapMatch
                      ? [mapMatchedlineStringSource]
                      : []),
                    lineStringSource,
                    // assetPointSource,
                    detailTripPointSource,
                    tripEndPointSource,
                  ]}
                  bounds={locationBbox?.bbox}
                  maxBounds={true}
                  zoom={1}
                  mapStyle={storedMapStyle}
                  style={{
                    minHeight: 240,
                  }}
                  googleAttributionStyle={{
                    position: 'absolute',
                    left: '100px',
                    bottom: '2px',
                    zIndex: 1,
                  }}
                />
                {!!canMapMatch && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }}>
                    <div>
                      <FormControlLabel
                        sx={{
                          backgroundColor: 'rgba(0,0,0,0.5)',
                          m: 0,
                          padding: 0.5,
                        }}
                        control={
                          <Checkbox
                            style={{
                              color: 'white',
                              padding: 0,
                            }}
                            disableRipple
                            checked={shouldMapMatch}
                            value={shouldMapMatch}
                            onChange={() => setShouldMapMatch(!shouldMapMatch)}
                          />
                        }
                        label={
                          <span
                            style={{
                              color: 'white',
                              paddingLeft: 8,
                              paddingRight: 8,
                            }}>
                            {t('Estimate Route')}
                          </span>
                        }
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {!data && (
              <Skeleton variant='rounded' width={'100%'} height={240} />
            )}
            {data && (
              <TableContainer
                component={Paper}
                sx={{ overflow: 'hidden', boxShadow: 0 }}>
                <Table
                  sx={{ minWidth: 650 }}
                  size='small'
                  aria-label='a dense table'>
                  <TableBody>
                    <MyTableRow label={t('Asset')} value={data.name} />
                    <MyTableRow
                      label={t('Device')}
                      value={getDeviceFullName(data.deviceType)}
                    />
                    {data.assetType == AssetType.VEHICLE && data.driverUuid && (
                      <MyTableRow
                        label={t('Driver')}
                        value={data.driverName || 'Unknown'}
                        valueStyle={
                          !!data.driverId && {
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }
                        }
                        onClick={() => navigate(`/drivers/${data.driverUuid}`)}
                      />
                    )}
                    <MyTableRow
                      label={t('Start / End')}
                      value={(() => {
                        return (
                          <>
                            {moment(data.startTimestamp).format(
                              'YYYY-MM-DD HH:mm:ss'
                            )}
                            {'  /  '}
                            {moment(data.endTimestamp).format(
                              'YYYY-MM-DD HH:mm:ss'
                            )}
                          </>
                        );
                      })()}
                    />
                    <MyTableRow
                      label={t('Duration')}
                      value={(() => {
                        const days = moment(data.endTimestamp).diff(
                          moment(data.startTimestamp),
                          'days'
                        );
                        const hours =
                          moment(data.endTimestamp).diff(
                            moment(data.startTimestamp),
                            'hours'
                          ) % 24;
                        const minutes =
                          moment(data.endTimestamp).diff(
                            moment(data.startTimestamp),
                            'minutes'
                          ) % 60;
                        const showMin = minutes
                          ? ` ${minutes.toString().padStart(2, '0')}m`
                          : '';
                        const showHrs = hours >= 1 ? ` ${hours}h` : '';
                        const showDays = days >= 1 ? `${days}d` : '';
                        return `${showDays}${showHrs}${showMin}`;
                      })()}
                    />
                    {data.distance && (
                      <MyTableRow
                        label={t('Distance')}
                        value={(() => {
                          return (
                            <>
                              {convertUnitForAssetType(
                                'distance',
                                data.assetType,
                                data.distance || 0
                              )}{' '}
                              {getUnitForAssetType('distance', data.assetType)}
                            </>
                          );
                        })()}
                      />
                    )}
                    {data.assetType === AssetType.VEHICLE && data.carbon && (
                      <MyTableRow
                        label={t('CO₂')}
                        valueStyle={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        value={<>{data.carbon} Kg</>}
                      />
                    )}
                    {(data.assetType === AssetType.VEHICLE ||
                      data.assetType === AssetType.VESSEL) &&
                      data.fuelUsed && (
                        <MyTableRow
                          label={t('Fuel Used')}
                          valueStyle={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          value={<>{data.fuelUsed} L</>}
                        />
                      )}
                    <MyTableRow
                      label={t('From')}
                      valueStyle={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      value={
                        <>
                          {data.startFormattedAddress ??
                            `${data.startPosition.lat.toFixed(
                              7
                            )}, ${data.startPosition.lon.toFixed(7)}`}
                        </>
                      }
                    />
                    <MyTableRow
                      label={t('To')}
                      valueStyle={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      value={
                        <>
                          {data.endFormattedAddress ??
                            `${data.endPosition.lat.toFixed(
                              7
                            )}, ${data.endPosition.lon.toFixed(7)}`}
                        </>
                      }
                    />
                  </TableBody>
                </Table>
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Button
                    size='small'
                    onClick={() => {
                      navigate(`/assets/${data.assetUuid}`);
                    }}
                    sx={{
                      mb: 2,
                      mr: 2,
                      color: '#70A8CC',
                      textTransform: 'capitalize',
                      textAlign: 'right',
                    }}>
                    {t('View asset details')}
                  </Button>
                </Box>
              </TableContainer>
            )}
          </Grid>
        </Grid>
        {data &&
          [AssetType.CONTAINER, AssetType.TRAILER].includes(data.assetType) && (
            <Paper sx={{ height: '16rem', with: '100%', pt: '20px' }}>
              <ContainerLineChart
                assetType={data.assetType}
                widgetType='temperature'
                assetId={data.assetId}
                chartUnit='C'
                showTotal={false}
                startTimestamp={moment(data.startTimestamp).toDate()}
                endTimestamp={moment(data.endTimestamp).toDate()}
                interval={3}
                includeAllReports={true}
              />
            </Paper>
          )}
        <TripReports
          onRowClicked={(e: any) => setDetailTripPoint(e)}
          tripId={tripId}
          tripData={data}
          playbackOpen={playbackOpen}
          setPlaybackOpen={setPlaybackOpen}
        />
      </Box>
    </BoxedContainer>
  );
};

export default Trip;

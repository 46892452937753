import { useVerifyWhatsApp } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SnapGISLogoSVGFull from 'assets/original-full.svg';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const VerifyWhatsApp = () => {
  const { mutate, isLoading } = useVerifyWhatsApp();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (params && params.token) {
      mutate({ token: params.token });
    }
  }, [params]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          textAlign: 'center',
          padding: '1em',
        }}>
        <div>
          <img
            alt='SnapGIS logo'
            src={SnapGISLogoSVGFull}
            style={{ maxHeight: 30 }}
          />
        </div>
        <Typography variant='h6' style={{ color: 'white', marginTop: 16 }}>
          {t('Thank you for confirming your WhatsApp number!')}
        </Typography>
        {!isLoading && (
          <Button
            variant='outlined'
            color='success'
            onClick={() => {
              try {
                window.close();
                navigate('/');
              } catch (e) {}
            }}
            sx={{ marginTop: 4 }}>
            {t('You can now close this window')}
          </Button>
        )}
      </div>
    </>
  );
};

import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init(
    {
      backend: {
        queryStringParams: { v: '0.0.4' },
      },
      lng: 'en',
      debug: ['local', 'development'].includes(process.env.NODE_ENV),
      interpolation: {
        escapeValue: false,
      },
      returnNull: false,
      returnEmptyString: false,
      ...(['local', 'development'].includes(process.env.NODE_ENV) && {
        parseMissingKeyHandler: () => `**********`,
      }),
      load: 'languageOnly',
    },
    (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      if (['local', 'development'].includes(process.env.NODE_ENV)) {
        t('**********');
      }
    }
  );

export default i18n;

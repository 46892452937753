import React from 'react';
import Box from '@mui/material/Box';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useAuth, useLocalStorage } from '../../hooks';
import { useTranslation } from 'react-i18next';

const dashboardMap = [
  {
    index: 0,
    pathname: '/dashboard/vehicles',
    title: 'Vehicle Dashboard',
    userCapabilities: ['hasVehicles'],
  },
  {
    index: 1,
    pathname: '/dashboard/vessels',
    title: 'Vessel Dashboard',
    userCapabilities: ['hasVessels'],
  },
  {
    index: 2,
    pathname: '/dashboard/ais_vessels',
    title: 'AIS Vessel Dashboard',
    userCapabilities: ['hasAISVessels'],
  },
];

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const canAccess = (user: any, item: any) => {
  if (!item.userCapabilities) return item;
  const existsOnUser = item.userCapabilities.filter((c: any) => !!user[c]);
  if (item.someUserCapabilities && existsOnUser.length > 0) {
    return item;
  }
  if (existsOnUser.length === item.userCapabilities.length) {
    return item;
  }
  return null;
};

export const Dashboard = () => {
  const [persistedTab, setPersistedTab] = useLocalStorage('dashboardTab', null);
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth() as any;
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!location) return;
    if (!user) return;

    const pathNames = dashboardMap.map((item: any) => item.pathname);
    if (!pathNames.includes(location.pathname)) {
      const dashboards = dashboardMap.filter((item: any) => {
        return !!canAccess(user, item);
      });
      if (dashboards.length === 0) {
        navigate('/', { replace: true });
        return;
      }

      if (persistedTab !== null) {
        const d = dashboards.find((d: any) => d.index === persistedTab);
        if (d && d.pathname) {
          navigate(d.pathname, { replace: true });
          return;
        }
      }

      navigate(dashboards[0].pathname, { replace: true });
      return;
    }

    const dashboard = dashboardMap.find(
      (item: any) => item.pathname === location.pathname
    );

    setActiveTab(dashboard?.index ? dashboard.index : 0);
  }, [location, user]);

  const handleChange = (event: any) => {
    const dashboard = dashboardMap.find(
      (item: any) => item.index === event.target.value
    );

    if (!dashboard) return;

    setPersistedTab(dashboard.index);
    navigate(dashboard?.pathname);
  };

  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      <Box sx={{ mt: 1 }}>
        <div
          style={{
            display: 'flex',
            width: '95%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginLeft: '2.5%',
            marginTop: '2em',
          }}>
          <h1
            style={{
              fontSize: '1.8em',
              display: 'inline-flex',
            }}>
            {t('Dashboard')}
          </h1>
          <FormControl
            sx={{
              display: 'inline-flex',
              ml: 2,
            }}>
            <Select
              variant={'standard'}
              value={activeTab}
              label=''
              onChange={handleChange}>
              {dashboardMap
                .filter((item: any) => canAccess(user, item))
                .map((item: any, index: number) => (
                  <MenuItem key={index} value={item.index}>
                    {item.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </Box>
      <Outlet />
    </Box>
  );
};

import React, { useState } from 'react';
// eslint-disable-next-line import/named
import { Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PersonIcon from '@mui/icons-material/Person';
import SmsIcon from '@mui/icons-material/Sms';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Badge, IconButton, Tooltip } from '@mui/material';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import BoxedContainer from 'components/BoxedContainer';
import ConfirmDeleteDialog from 'components/SystemDialogs/ConfirmDeleteDialog';
import Table from 'components/Table';
import useActionMenuContext, {
  ActionMenuModuleTypes,
} from 'context/ActionMenuContext';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import { useContacts, useDeleteContact } from 'hooks/contacts/useContacts';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import { useTranslation } from 'react-i18next';
import ContactsToolbarWithUrlFilters from './ContactsToolbar';

const commonColDefOptions = {
  // resizable: true,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
};

function getColumns({
  t,
  handleEditContact,
  handleDeleteContact,
  isDeleting,
  canWrite,
}: any): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'avatar',
      headerName: '',
      ...commonColDefOptions,
      flex: 0.2,
      renderCell: r => {
        return (
          <Tooltip title={r?.row?.user ? 'User' : 'External Contact'}>
            {r?.row?.user ? (
              <PersonIcon sx={{ fontSize: '1.5rem' }} />
            ) : (
              <PermIdentityIcon sx={{ fontSize: '1.5rem' }} />
            )}
          </Tooltip>
        );
      },
    }, // @ts-ignore
    // @ts-ignore
    {
      field: 'name',
      headerName: t('Name'),
      ...commonColDefOptions,
      renderCell: r => {
        return `${r?.row?.firstName} ${r?.row?.lastName}`;
      },
    }, // @ts-ignore
    {
      field: 'group',
      headerName: t('Group'),
      ...commonColDefOptions,
      renderCell: r => {
        return `${r?.row?.group.name}`;
      },
    }, // @ts-ignore
    {
      field: 'email',
      headerName: t('Email'),
      ...commonColDefOptions,
    }, // @ts-ignore
    {
      field: 'mobileNumber',
      headerName: t('Mobile'),
      ...commonColDefOptions,
      renderCell: r => {
        return r?.row?.mobileNumber ?? '';
      },
    }, // @ts-ignore
    {
      field: 'notifications',
      headerName: t('# Notifications'),
      ...commonColDefOptions,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: r => {
        if (!r?.row?.notifications || !r?.row?.notifications?.length) return 0;
        return (
          <Tooltip
            title={
              <ol style={{ paddingLeft: 16 }}>
                {r?.row?.notifications?.map((notification: any) => (
                  <li key={notification.notificationSettingId}>
                    {t('Receives')}{' '}
                    <b>{t(`event_trigger.${notification.eventTrigger}`)}</b>
                    &nbsp;{t('events')}&nbsp;{t('for')}&nbsp;&quot;
                    <b>{notification.group.name}</b>&quot;&nbsp;{t('via')}&nbsp;
                    {notification.channel}
                  </li>
                ))}
              </ol>
            }>
            <div>{r?.row?.notifications.length.toString()}</div>
          </Tooltip>
        );
      },
    }, // @ts-ignore
    {
      field: 'preferredMobileChannel',
      headerName: t('Channel'),
      ...commonColDefOptions,
      flex: 0.3,
      headerAlign: 'center',
      align: 'center',
      renderCell: r => {
        const renderIcon = (tooltip: any, iconComponent: any) => (
          <Tooltip title={tooltip}>
            <Badge
              color={r?.row?.mobileChannelVerified ? 'success' : 'warning'}
              overlap='circular'
              badgeContent=' '
              invisible={r?.row?.preferredMobileChannel == 'sms'}
              variant='dot'>
              {iconComponent}
            </Badge>
          </Tooltip>
        );
        switch (r?.row?.preferredMobileChannel) {
          case 'whatsapp':
            return renderIcon(
              `WhatsApp (${
                r?.row?.mobileChannelVerified
                  ? t('verified')
                  : t('waiting_for_user_verification')
              })`,
              <WhatsAppIcon />
            );
          case 'telegram':
            return renderIcon('Telegram', <TelegramIcon />);
          case 'sms':
            return renderIcon('SMS', <SmsIcon />);
        }
      },
    }, // @ts-ignore
    ...(canWrite('CONTACT')
      ? [
          {
            headerName: '',
            ...commonColDefOptions,
            minWidth: 90,
            maxWidth: 100,
            align: 'center',
            flex: 0.5,
            renderCell: (r: any) => {
              return (
                <>
                  <Tooltip title='Edit Contact'>
                    <IconButton
                      disabled={false || !r?.row?.editable || isDeleting}
                      onClick={() => handleEditContact(r?.row?.contactId)}>
                      <EditIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    disableInteractive
                    title={
                      r?.row?.user && r?.row?.user?.name
                        ? t('contact_user_link_delete_warning', {
                            username: r?.row?.user?.name,
                          })
                        : 'Delete Contact'
                    }>
                    <span>
                      <IconButton
                        disabled={
                          !r?.row?.editable || isDeleting || !!r?.row?.user
                        }
                        onClick={() => handleDeleteContact(r?.row)}>
                        <Delete fontSize='small' />
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              );
            },
          },
        ]
      : []),
  ];
}

export const Contacts = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteDialog, setDeleteDialog] = useState<any>(null);
  const { t } = useTranslation();
  const { setActive } = useActionMenuContext();
  const { openRightSidebar } = useUIContext();
  const { mutate: deleteContact, isLoading: isDeleting } = useDeleteContact();
  const { canWrite } = usePermissions();
  const pageSize = 10;
  const [page, setPage] = React.useState(0);
  const [searchParams] = useComplexSearchParams();
  const { data, isLoading } = useContacts({
    page: page + 1,
    pageSize,
    searchTerm,
    filterBy: searchParams,
  }) as any;
  const [rowCountState, setRowCountState] = React.useState(0);

  const handleAddContact = () => {
    setActive(ActionMenuModuleTypes.AddContact);
    openRightSidebar();
  };

  const handleDeleteContact = (contact: any) => {
    setDeleteDialog(contact);
  };

  const handleEditContact = (id: number) => {
    setActive(ActionMenuModuleTypes.EditContact, { contactId: id });
    openRightSidebar();
  };

  React.useEffect(() => {
    setRowCountState(data?.numRecords ?? 0);
  }, [data?.numRecords, setRowCountState]);

  return (
    <BoxedContainer>
      <ContactsToolbarWithUrlFilters
        handleAddContact={handleAddContact}
        setSearchTerm={setSearchTerm}
      />
      <Table
        data={data && data.contacts}
        isLoading={isLoading}
        getRowId={(row: any) => row.contactId}
        columns={getColumns({
          t,
          handleDeleteContact,
          handleEditContact,
          isDeleting,
          canWrite,
        })}
        rowCount={rowCountState}
        disableColumnFilter={true}
        disableColumnMenu={true}
        onPageChange={newPage => setPage(newPage)}
        paginationMode='server'
        pageSize={pageSize}
      />
      <ConfirmDeleteDialog
        open={!!deleteDialog}
        onCancel={() => {
          setDeleteDialog(null);
        }}
        onConfirm={() => {
          if (deleteDialog) deleteContact(deleteDialog?.contactId);
        }}
        title={t('Confirm to delete asset')}
        content={
          <>
            {t('Are you sure you want to delete the contact')}{' '}
            <b>
              {deleteDialog?.firstName} {deleteDialog?.lastName}
            </b>
            ?
          </>
        }
      />
    </BoxedContainer>
  );
};

export default Contacts;

import React from 'react';
import CardComponent from 'components/Card';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ReportCard = ({ Icon, title, description, url, className }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <CardComponent
      className={className}
      Icon={Icon}
      title={title}
      description={description}
      button={t('View Report')}
      onClick={() => {
        navigate(url, { state: { title, description } });
      }}
    />
  );
};

export default ReportCard;

import { LinkOff } from '@mui/icons-material';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditLocationAltOutlined from '@mui/icons-material/EditLocationAltOutlined';
import LinkIcon from '@mui/icons-material/Link';
import LocationOffOutlined from '@mui/icons-material/LocationOffOutlined';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import {
  CircularProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import './popup.scss';

const ContextMenu = (
  {
    createNewLocation,
    centerMap,
    editLocation,
    deleteLocation,
    connectUpstream,
    deletePipeline,
    requestAssetLocation,
    ignitionLockout,
    requestReboot,
    copyCoordinates,
  }: any,
  canWriteLocation: boolean,
  t: any,
  type?: string | null
) => {
  return (
    <Paper sx={{ maxWidth: '100%' }}>
      <MenuList>
        {canWriteLocation && editLocation && (
          <MenuItem onClick={() => editLocation()}>
            <ListItemIcon>
              <EditLocationAltOutlined fontSize='small' />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: '.85rem' }}>
              {t('Edit Location')}
            </ListItemText>
          </MenuItem>
        )}
        {canWriteLocation && deleteLocation && (
          <MenuItem onClick={() => deleteLocation()}>
            <ListItemIcon>
              <LocationOffOutlined fontSize='small' htmlColor='#FD6262' />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: '.85rem', color: '#FD6262' }}>
              {t('Delete Location')}
            </ListItemText>
          </MenuItem>
        )}
        {canWriteLocation && !!createNewLocation && !type && (
          <MenuItem onClick={() => createNewLocation()}>
            <ListItemIcon>
              <AddLocationAltOutlinedIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: '.85rem' }}>
              {t('Add a new location')}
            </ListItemText>
          </MenuItem>
        )}
        {centerMap && (
          <MenuItem onClick={() => centerMap()}>
            <ListItemIcon>
              <LocationSearchingOutlinedIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: '.85rem' }}>
              {t('Zoom to')}
            </ListItemText>
          </MenuItem>
        )}
        {centerMap && (
          <MenuItem onClick={() => copyCoordinates()}>
            <ListItemIcon>
              <ContentCopyIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: '.85rem' }}>
              {t('Copy coordinates')}
            </ListItemText>
          </MenuItem>
        )}
        {connectUpstream && (
          <MenuItem onClick={() => connectUpstream()}>
            <ListItemIcon>
              <LinkIcon fontSize={'small'} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: '.85rem' }}>
              {t('Connect upstream')}
            </ListItemText>
          </MenuItem>
        )}
        {deletePipeline && (
          <MenuItem onClick={() => deletePipeline()}>
            <ListItemIcon>
              <LinkOff fontSize={'small'} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: '.85rem' }}>
              {t('Delete Pipeline')}
            </ListItemText>
          </MenuItem>
        )}
        {requestAssetLocation && (
          <MenuItem onClick={() => requestAssetLocation()}>
            <ListItemIcon>
              <TrackChangesIcon fontSize={'small'} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: '.85rem' }}>
              {t('Request location update')}
            </ListItemText>
          </MenuItem>
        )}
        {ignitionLockout && ignitionLockout.requestHandler && (
          <>
            {(() => {
              const { lastRequestedState, currentState } = ignitionLockout;
              if (isPending(ignitionLockout)) {
                return (
                  <>
                    {IgnitionMenuItem({
                      currentState: lastRequestedState,
                      disabled: true,
                      handler: () => {},
                      t,
                    })}
                  </>
                );
              }
              return (
                <>
                  {IgnitionMenuItem({
                    currentState,
                    disabled: false,
                    handler: () =>
                      ignitionLockout.requestHandler(
                        !ignitionLockout.currentState
                      ),
                    t,
                  })}
                </>
              );
            })()}
          </>
        )}
        {requestReboot && (
          <MenuItem onClick={() => requestReboot()}>
            <ListItemIcon>
              <RestartAltIcon fontSize='small' htmlColor='#FD6262' />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: '.85rem', color: '#FD6262' }}>
              {t('Reboot Device')}
            </ListItemText>
          </MenuItem>
        )}
      </MenuList>
    </Paper>
  );
};

const isPending = (args: any) => {
  const { lastRequestedAt, lastRequestedState, currentState } = args;
  const requestedAt = moment(lastRequestedAt);
  const fiveMinsAgo = moment().subtract(5, 'minutes');
  if (
    lastRequestedAt !== null &&
    lastRequestedAt !== undefined &&
    lastRequestedState !== null &&
    lastRequestedState !== undefined &&
    requestedAt.isAfter(fiveMinsAgo) &&
    currentState !== lastRequestedState
  ) {
    return true;
  }
  return false;
};

const IgnitionMenuItem = ({
  currentState,
  disabled = false,
  handler,
  t,
}: {
  currentState: any;
  disabled: boolean;
  handler: any;
  t: any;
}) => {
  return (
    <MenuItem disabled={disabled} onClick={handler}>
      <ListItemIcon>
        {currentState ? (
          <LockOpenIcon fontSize={'small'} sx={{ color: '#ffbf00' }} />
        ) : (
          <LockIcon fontSize={'small'} sx={{ color: '#ffbf00' }} />
        )}
      </ListItemIcon>
      <ListItemText
        sx={{ color: '#ffbf00' }}
        primaryTypographyProps={{
          fontSize: '.85rem',
          display: 'flex',
          alignItems: 'center',
        }}>
        {disabled
          ? currentState
            ? t('Engaging lockout')
            : t('Disengaging lockout')
          : currentState
          ? t('Disengage lockout')
          : t('Engage lockout')}
        {disabled ? (
          <CircularProgress color='secondary' size={12} sx={{ ml: 1 }} />
        ) : null}
      </ListItemText>
    </MenuItem>
  );
};

export default ContextMenu;

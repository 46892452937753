import React from 'react';
import './hoverPopup.scss';
import { Link } from '@mui/material';

const ClusterPopup = ({
  features,
  limit,
  onClick,
  t,
}: {
  features: any;
  limit?: number;
  onClick?: Function;
  t: any;
}) => {
  let featureList = features.sort((a: any, b: any) =>
    a.properties?.activeAlert === 'false'
      ? 1
      : 'false' !== a.properties?.activeAlert
      ? -1
      : 0
  );
  if (!!limit) {
    featureList = featureList.slice(0, limit);
  }
  return (
    <div className={`hover-popup popup--normal`}>
      <div className='hover-popup-inner'>
        <div>
          <ul style={{ margin: 0, padding: 0 }}>
            {featureList.slice(0, limit).map((feature: any) => {
              const { name, activeAlert, assetUuid, assetId } =
                feature.properties;
              const isPanic = 'panic' === activeAlert;
              return (
                <Link
                  onClick={() =>
                    onClick && onClick(assetId, true, true, true, 16)
                  }
                  key={name}
                  style={{
                    textDecoration: 'none',
                    outline: 'none',
                    WebkitTapHighlightColor: 'transparent',
                    WebkitTouchCallout: 'none',
                    WebkitUserSelect: 'none',
                    KhtmlUserSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                    userSelect: 'none',
                  }}>
                  <li
                    className='hoverable-text'
                    style={{
                      color: isPanic ? '#ff5b5b' : 'white',
                      fontWeight: isPanic ? 'bold' : 'normal',
                    }}>
                    {name}
                  </li>
                </Link>
              );
            })}
          </ul>
          {!!limit && features.length > limit && (
            <small>
              {t('Click Cluster to see')} {features.length - limit} {t('more')}{' '}
              {t(features.length === 1 ? 'Asset' : 'Assets')}.
            </small>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClusterPopup;

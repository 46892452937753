import React, { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import usePermissions from 'context/PermissionContext';
import { Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';

interface LinkTabProps {
  label: string;
  href: string;
}

function LinkTab(props: LinkTabProps) {
  let navigate = useNavigate();

  return (
    <Tab
      component='a'
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        navigate(props.href);
      }}
      {...props}
    />
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabItems = [
  {
    label: 'Trips',
    href: '/reports/trips',
  },
];

const ReportDetails = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<number | null>(null);
  const location = useLocation();
  const { canRead } = usePermissions();
  let navigate = useNavigate();

  useEffect(() => {
    if (location && location.pathname) {
      tabItems.find((item, index) => {
        if (item.href === location.pathname) {
          setValue(index);
        }
        if ('/reports' === location.pathname) {
          navigate(tabItems[0].href);
        }
      });
    }
  }, [location]);

  return (
    <Box sx={{ width: '90%', marginLeft: '5%', mt: 1 }}>
      <Box sx={{}}>
        <Button
          size={'small'}
          startIcon={<ArrowBackIcon sx={{ height: '.9em' }} />}
          sx={{ textTransform: 'capitalize' }}
          onClick={() => navigate(-1)}>
          {t('Back')}
        </Button>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default ReportDetails;

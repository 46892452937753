import {
  ClearAll,
  Co2,
  CrisisAlert,
  DirectionsBoat,
  Groups,
  LocationOn,
  NoCrash,
  Percent,
  Route,
} from '@mui/icons-material';
import SpeedIcon from '@mui/icons-material/Speed';
import { Chip, Divider, Grid, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import SearchInputForm from 'components/ActionMenu/modules/GlobalSearch/SearchInputForm';
import BoxedContainer from 'components/BoxedContainer';
import ContainerIcon from 'components/CustomIcons/ContainerIcon';
import TrailerIcon from 'components/CustomIcons/TrailerIcon';
import { Formik } from 'formik';
import { useCurrentUser } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GlobalSearchFormSchema from 'schemas/GlobalSearch';
import AssetLogIcon from '../../components/CustomIcons/AssetLogIcon';
import FuelCarbonIcon from '../../components/CustomIcons/FuelCarbonIcon';
import LocationActivityIcon from '../../components/CustomIcons/LocationActivityIcon';
import UtilizationIcon from '../../components/CustomIcons/UtilizationIcon';
import VehicleTripIcon from '../../components/CustomIcons/VehicleTrip';
import VesselTripIcon from '../../components/CustomIcons/VesselTrip';
import ReportCard from './ReportCard';
import reportsList from './reports.json';

interface LinkTabProps {
  label: string;
  href: string;
  onClick?: any;
}

const reportIcons = {
  ClearAll: {
    icon: ClearAll,
  },
  LocationOn: {
    icon: LocationOn,
  },
  NoCrash: {
    icon: NoCrash,
  },
  Route: {
    icon: Route,
  },
  CrisisAlert: {
    icon: CrisisAlert,
  },
  Groups: {
    icon: Groups,
  },
  Percent: {
    icon: Percent,
  },
  Boat: {
    icon: DirectionsBoat,
  },
  Co2: {
    icon: Co2,
  },
  VehicleTrips: {
    icon: VehicleTripIcon,
  },
  VesselVoyages: {
    icon: VesselTripIcon,
  },
  AssetLog: {
    icon: AssetLogIcon,
  },
  LocationActivity: {
    icon: LocationActivityIcon,
  },
  Utilization: {
    icon: UtilizationIcon,
  },
  FuelCarbon: {
    icon: FuelCarbonIcon,
  },
  Speed: {
    icon: SpeedIcon,
  },
  TrailerIcon: {
    icon: TrailerIcon,
  },
  ContainerIcon: {
    icon: ContainerIcon,
  },
} as any;

const hasTypeCondition = (
  userData: {
    hasAISVessels: boolean;
    hasVehicles: boolean;
    hasVessels: boolean;
    hasContainers: boolean;
    hasTrailers: boolean;
  },
  i: { type: string }
) => {
  return (
    (userData?.hasVessels && i.type.includes('vessel')) ||
    (userData?.hasAISVessels && i.type.includes('vessel_ais')) ||
    (userData?.hasVehicles && i.type.includes('vehicle')) ||
    (userData?.hasContainers && i.type.includes('container')) ||
    (userData?.hasTrailers && i.type.includes('trailer')) ||
    i.type === 'general'
  );
};

const Reports = () => {
  const [visibleReportCards, setVisibleReportCards] = React.useState<
    Array<any>
  >([]);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [filter, setFilter] = React.useState<string | null>(null);
  const { t } = useTranslation();
  const { data: userData, isLoading, refetch } = useCurrentUser() as any;

  React.useEffect(() => {
    refetch();
  }, []);

  const typesFromReportsList = React.useCallback(() => {
    return (
      reportsList
        // .filter((report: any) => hasTypeCondition(userData, report))
        .flatMap(report => report.type) // Flatten the array of types
        .filter((value, index, array) => array.indexOf(value) === index)
    ); // Remove duplicates
  }, []);

  const setReportCardVisibility = React.useCallback(() => {
    if (!filter && searchTerm.length < 1) {
      setVisibleReportCards(
        structuredClone(reportsList).filter((i: any) => {
          return hasTypeCondition(userData, i);
        })
      );
      return;
    }
    setVisibleReportCards(
      structuredClone(reportsList).filter((i: any) => {
        if (!hasTypeCondition(userData, i)) return;
        const filterCondition = () =>
          i.type === filter ||
          i.name.includes(filter) ||
          i.tags.includes(filter);
        const fixedType = Array.isArray(i.type) ? i.type.join('-') : i.type;
        const searchCondition = () =>
          i.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
          fixedType.toLowerCase().includes(searchTerm.toLowerCase()) ||
          i.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          i.tags.includes(searchTerm.toLowerCase());

        if (filter && searchTerm) return filterCondition() && searchCondition();
        if (filter) return filterCondition();
        if (searchTerm) return searchCondition();
      })
    );
  }, [userData, filter, searchTerm]);

  React.useEffect(() => {
    if (!!userData) setReportCardVisibility();
  }, [filter, userData]);

  React.useEffect(() => {
    if (!!userData) setReportCardVisibility();
  }, [searchTerm, userData]);

  return (
    <BoxedContainer>
      <Box sx={{ mb: 4 }}>
        <Formik
          validateOnMount={true}
          initialValues={{
            search: '',
          }}
          validationSchema={GlobalSearchFormSchema(t)}
          validateOnChange={false}
          validateOnBlur={false}
          onReset={() => {
            setSearchTerm('');
          }}
          onSubmit={(values: any, { setSubmitting }: any) => {
            setSearchTerm(values.search);
            setSubmitting(false);
          }}>
          {(props: any) => (
            <SearchInputForm
              {...props}
              isLoading={false}
              setSearchTerm={setSearchTerm}
              placeholder={t('Search for a Report')}
              disabled={
                isLoading ||
                (!userData?.hasAISVessels &&
                  !userData?.hasVehicles &&
                  !userData?.hasVessels)
              }
            />
          )}
        </Formik>
        <Box sx={{ mt: 1 }}>
          {isLoading ? (
            <Chip
              sx={{ mr: 1, color: 'rgba(255,255,255,0.5)' }}
              key={'loading'}
              label={t('Loading…')}
              variant={'outlined'}
              disabled={isLoading}
            />
          ) : (
            typesFromReportsList().map((item: any) => {
              if ('vessel_ais' === item && !userData?.hasAISVessels) return;
              if ('vessel' === item && !userData?.hasVessels) return;
              if ('vehicle' === item && !userData?.hasVehicles) return;
              if ('trailer' === item && !userData?.hasTrailers) return;
              if ('container' === item && !userData?.hasContainers) return;
              if (
                !userData?.hasAISVessels &&
                !userData?.hasVehicles &&
                !userData?.hasVessels &&
                !userData?.hasTrailers &&
                !userData?.hasContainers
              )
                return;
              return (
                <Chip
                  sx={{ mr: 1 }}
                  key={item}
                  label={t(`reports.chips.${item}`)}
                  variant={filter === item ? 'filled' : 'outlined'}
                  onClick={() => {
                    setFilter(filter === item ? null : item);
                  }}
                />
              );
            })
          )}
        </Box>
        <Divider sx={{ mb: 2, mt: 1 }} />
        <Grid container spacing={6} sx={{ py: 0.5 }}>
          {isLoading ? (
            <>
              <Grid item xs={4} key={'loading1'}>
                <Skeleton height={240} width={'100%'} animation='wave' />
              </Grid>
              <Grid item xs={4} key={'loading2'}>
                <Skeleton height={240} width={'100%'} animation='wave' />
              </Grid>
              <Grid item xs={4} key={'loading3'}>
                <Skeleton height={240} width={'100%'} animation='wave' />
              </Grid>
              <Grid item xs={4} key={'loading4'}>
                <Skeleton height={240} width={'100%'} animation='wave' />
              </Grid>
              <Grid item xs={4} key={'loading5'}>
                <Skeleton height={240} width={'100%'} animation='wave' />
              </Grid>
              <Grid item xs={4} key={'loading6'}>
                <Skeleton height={240} width={'100%'} animation='wave' />
              </Grid>
            </>
          ) : (
            visibleReportCards &&
            visibleReportCards.map((reportCard: any) => {
              if (!reportCard) return;
              if ('vessel_ais' === reportCard.type && !userData?.hasAISVessels)
                return;
              if ('vessel' === reportCard.type && !userData?.hasVessels) return;
              if ('vehicle' === reportCard.type && !userData?.hasVehicles)
                return;
              if ('container' === reportCard.type && !userData?.hasContainers)
                return;
              if ('trailer' === reportCard.type && !userData?.hasTrailers)
                return;
              return (
                <Grid item xs={4} key={reportCard?.name}>
                  <ReportCard
                    className={reportCard?.className}
                    title={t(reportCard?.i18n)}
                    description={t(reportCard?.description)}
                    url={reportCard?.link}
                    Icon={
                      reportIcons[reportCard.icon]
                        ? reportIcons[reportCard.icon].icon
                        : null
                    }
                  />
                </Grid>
              );
            })
          )}
        </Grid>
      </Box>
    </BoxedContainer>
  );
};

export default Reports;

/* eslint-disable react/jsx-key */
import LockIcon from '@mui/icons-material/Lock';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Link,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import useUIContext from 'context/UIContext';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import { useCurrentUser } from 'hooks';
import {
  useMfaQr,
  useSubmitMfaCode,
  useSubmitSmsCode,
} from 'hooks/auth/useTwoFa';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MfaCodeFormSchema from 'schemas/MfaCode';
import SmsCodeFormSchema from 'schemas/SmsCode';
import errorMessage from 'services/errorMessage';

const FormFields = ({
  mutationError,
  isSuccess,
  isLoading,
  codeType = 'mfa',
}: any) => {
  const { t } = useTranslation();
  const [serverError, setServerError] = useState<any>();
  const { values, isSubmitting, resetForm, submitForm, isValid }: any =
    useFormikContext();

  useEffect(() => {
    if (isSubmitting) {
      setServerError(null);
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
  }, [isSuccess]);

  useEffect(() => {
    setServerError(null);
  }, [values]);

  useEffect(() => {
    setServerError(mutationError);
  }, [mutationError]);

  return (
    <Form>
      <div className='pv-10'>
        <Grid container spacing={2} sx={{ mt: -4 }}>
          <Grid item xs={4}>
            <Field
              component={FormikTextField}
              sx={{ width: '100%' }}
              name={codeType == 'mfa' ? 'mfaCode' : 'smsCode'}
              type={codeType == 'mfa' ? 'mfaCode' : 'smsCode'}
              variant='standard'
              placeholder={codeType == 'mfa' ? t('mfa_code') : t('sms_code')}
              inputProps={{ maxLength: 6 }}
            />
          </Grid>

          <Grid item xs={3}>
            <Button
              onClick={submitForm}
              variant='contained'
              color='primary'
              sx={{ width: '100%' }}
              disabled={!isValid}>
              {t('Submit')}
            </Button>
          </Grid>
        </Grid>
      </div>
      {isLoading && <LinearProgress />}
      <Collapse in={serverError}>
        <Alert variant='outlined' severity='error' sx={{ mb: 1 }}>
          {errorMessage(t, serverError)}
        </Alert>
      </Collapse>
    </Form>
  );
};

const TwoFa = ({ open, setOpen }: any) => {
  const { t } = useTranslation();
  const { setFlashMessage } = useUIContext();
  const {
    mutate,
    error,
    isLoading: isMfaLoading,
    isSuccess: isMfaSuccess,
  } = useSubmitMfaCode();
  const {
    mutate: submitSmscode,
    error: mutationSmsError,
    isSuccess: isSmsCodeSuccess,
    isLoading: isSmsLoading,
  } = useSubmitSmsCode();
  const { data }: any = useCurrentUser();
  const { data: qrCode }: any = useMfaQr();
  const [openModal, setOpenModal] = React.useState(false);
  const [mutationError, setMutationError] = React.useState(null);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [showDownloadBtn, setShowDownloadBtn] = React.useState(false);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleClickOpen = () => {
    if (!data.mobileNumber || data.mobileNumber == '') {
      setFlashMessage({
        message: t('2fa.require_mobile_number'),
        severity: 'error',
      });
      return;
    }

    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpen(false);
    setActiveStep(0);
    setShowDownloadBtn(false);
  };

  const submitMfaCode = (data: any) => {
    const { mfaCode } = data;
    mutate({
      mfaCode,
    });
  };

  const submitSmsCode = (data: any) => {
    const { smsCode } = data;
    submitSmscode({
      smsCode,
    });
  };

  useEffect(() => {
    open ? handleClickOpen() : handleClose();
  }, [open]);

  useEffect(() => {
    if (isMfaSuccess) {
      handleComplete();
    }
  }, [isMfaSuccess]);

  useEffect(() => {
    if (isSmsCodeSuccess) {
      handleClose();
    }
  }, [isSmsCodeSuccess]);

  useEffect(() => {
    setMutationError(error);
  }, [error]);

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setMutationError(null);
    handleNext();
  };

  const StepMfa = () => {
    return (
      <Stepper activeStep={activeStep} orientation='vertical' nonLinear>
        <Step key={'step-0'} completed={completed[0]}>
          <StepButton onClick={handleStep(0)}>
            {t('2fa.steps.0.title')}
          </StepButton>
          <StepContent>
            <Box sx={{ my: 2 }}>
              {!showDownloadBtn && (
                <div>
                  <Typography sx={{ mb: 2 }}>
                    {t('2fa.steps.0.download_description')}
                  </Typography>
                  <Button
                    variant='contained'
                    onClick={() => setShowDownloadBtn(true)}
                    sx={{ mt: 1, mr: 1 }}>
                    {t('Download Google Authenticator')}
                  </Button>
                  <Button
                    variant='contained'
                    onClick={handleComplete}
                    sx={{ mt: 1, mr: 1 }}>
                    {t('I already have Google Authenticator')}
                  </Button>
                </div>
              )}
            </Box>
            {showDownloadBtn && (
              <>
                <Typography>
                  <Trans
                    i18nKey='2fa.steps.0.description'
                    t={t}
                    components={[
                      <Link
                        href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US'
                        target='_blank'></Link>,
                    ]}
                  />
                </Typography>
                <Box sx={{ my: 1, mt: 2 }}>
                  <img
                    style={{
                      border: '10px solid white',
                      width: '12rem',
                    }}
                    src={
                      'data:image/gif;base64,R0lGODdhfQB9AIAAAAAAAP///ywAAAAAfQB9AAAC/4SPqbvhDxOcgdlD4928e5s9Ulh9CFma6qqiY8gCbkzXyoyRMW73u34Crni+DeqoyYmCsKLS8UISo9LmM2WY9ibUKuXiJXKZGee1G14y0tZs0t0ujq9sKKg+h9vpb3kfn3fTB/Y3yBLoRfURqOiBaDhUKNXYwUiGZSS5V/M4ebkIqYdJqCbq04lEyWF55qg5evgqFipYWruZaau1VkWKy9pAC3yLuRvsyatLq0oMqhznexTNPH1nW813bZ26Xbz82e38K7TaGw5eKfzdyi6KJ+6tnYxLfd4uAyh/PK7fbF/+jF8+ePXmwbqHzdSld64CHtz3EF8/ZqgAEUQHkN7COv8FFWYrs9EhQ4MjPUJEE2dWvogrBbKURU4iyI9hopWU+VJkTJUDu90cVpHnl5BpzGSM55LiK6NMcyXFiFPpxKZmKkp9ipAqVatQuWbVytSryahd13FqmcjnUqJo27rtJxTl27loba6lSTcvR7UO5er9W5MvVneAjZGlaVfnVIMXGf/DCXRnzMZHE/pDLBjp0EiLx3Y8TBiuZGgNNTo+fZJtYpfDLLtmG1kxN5IwRa/0a/kmbtWZ02JGDdkcbdk5e/5OjXcy7+PEfiq/p3t5aNPTA6cjvns4ddDN93JmXXu74djGW4TXTH4zcojRr4PHnvl1cmTm4TPPvr47mx/0g7//9StUXb2dJ+B8M1XXHoIEtgTgaG6tpp5/D8LmYFsQBjVXgykV9lxlFNq2HYSf+VbfewdeVmKE6RmWm3D5wSOWfidSJmGEn4kIlYYhPkYiiibEFR+P3qXIYo5lkQbdkD+66FRx6KlDXJHa2fjYVVQmuV8s/ck31ngLInmUlJ4ZaeCV7N11Zl+laXZhhzKyGeRX+a3IJI5y1njjkeIpaeeYzLWmp5M99skdfoUKqeaUg1YpXY0Y2pdmUWsS2mOAUJbXJKVMWgqiRZPGqSCkeA64JaN/blrhKWb5aChYo9oAKHCtgiVmplruCWZTtXJp65SuzvpdRLzuugWHpfp6qzSg/zqa4ZqxRmrmq6FO6F60bXb6qF4wrqqjoF9SGya3H+I6I61oSnsoluQKa26i1Y14bp5axQgkcOn9iqiJcNp7KbvznlvvnP1um2WZAXO3KLIGV+icu8x2hm6B0yqr7pO5Girxw7N1+a2/745L8cQHZ2tto88iXCeZfp7l8Mkk32sqy4OlK/CxK9NMA72rvjxwzDkDvHPHw4pbrZU06gxxgk3G2GLLQRdMZLz57ntztydj/PTMTJNabrVb8+t00nw6KzXYJzasb9PrHs0wqqIa7bOVPG/4dqC8yt0x0l1z3CnbdEtaMcx3Zkwy3MHOrW+l5xkedZR5p2py2eE6TvnGKDH/TfWSmHMKON9nS7600O2uK/iLIOMra6PwYuumq3T27XPC+L6+duwpo067k0PbDEABADs='
                    }
                  />
                </Box>
                <Box sx={{ my: 2 }}>
                  <div>
                    <Button
                      variant='contained'
                      onClick={handleComplete}
                      sx={{ mt: 1, mr: 1 }}>
                      {t('continue')}
                    </Button>
                  </div>
                </Box>
              </>
            )}
          </StepContent>
        </Step>
        <Step key={'step-1'} completed={completed[0]}>
          <StepButton onClick={handleStep(0)}>
            {t('2fa.steps.1.title')}
          </StepButton>
          <StepContent>
            <Typography>
              <Trans
                i18nKey='2fa.steps.1.description'
                t={t}
                components={[
                  <Link
                    href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US'
                    target='_blank'>
                    {t('2fa.google_authenticator')}
                  </Link>,
                ]}
              />
            </Typography>
            <Box sx={{ my: 1, mt: 2 }}>
              <img src={qrCode} />
            </Box>
            <Box sx={{ my: 2 }}>
              <div>
                <Button
                  variant='contained'
                  onClick={handleComplete}
                  sx={{ mt: 1, mr: 1 }}>
                  {t('continue')}
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key={'step-2'} completed={completed[1]}>
          <StepLabel>{t('2fa.steps.2.title')}</StepLabel>
          <StepContent>
            <Typography sx={{ mb: 3 }}>
              {t('2fa.steps.2.description')}
            </Typography>
            <Formik
              isInitialValid={false}
              initialValues={{
                mfaCode: '',
              }}
              validationSchema={MfaCodeFormSchema(t)}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                submitMfaCode(values);
              }}>
              <FormFields
                codeType='mfa'
                mutationError={mutationError}
                isLoading={isMfaLoading}
                isSuccess={isMfaSuccess}
              />
            </Formik>
          </StepContent>
        </Step>
        <Step key={'step-3'}>
          <StepLabel>{t('2fa.steps.3.title')}</StepLabel>
          <StepContent>
            <Typography sx={{ mb: 3 }}>
              {t('2fa.steps.3.description')}
            </Typography>
            <Formik
              isInitialValid={false}
              initialValues={{
                smsCode: '',
              }}
              validationSchema={SmsCodeFormSchema(t)}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                submitSmsCode(values);
              }}>
              <FormFields
                codeType='sms'
                mutationError={mutationSmsError}
                isSuccess={isSmsCodeSuccess}
                isLoading={isSmsLoading}
              />
            </Formik>
          </StepContent>
        </Step>
      </Stepper>
    );
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      fullWidth={true}
      maxWidth='sm'>
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}>
          <LockIcon sx={{ mr: 1 }} color='success' /> {t('2fa.popup_title')}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <StepMfa />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TwoFa;

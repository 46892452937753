import React, { useState } from 'react';
// eslint-disable-next-line import/named
import { Lock } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SpeedIcon from '@mui/icons-material/Speed';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import ConfirmDeleteDialog from 'components/SystemDialogs/ConfirmDeleteDialog';
import useIgnitionLockout from 'hooks/assets/useIgnitionLockout';
import useReboot from 'hooks/assets/useReboot';
import useRequestLocation from 'hooks/assets/useRequestLocation';
import useVirtualOdometer from 'hooks/assets/useVirtualOdometer';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const DropdownMenu = (props: any) => {
  const [dropdownAnchor, setDropdownAnchor] =
    React.useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setDropdownAnchor(null);
  };
  const { t } = useTranslation();
  const [rebootDevice, setRebootDevice] = React.useState<any>();
  const [lockoutDevice, setLockoutDevice] = React.useState<any>();
  const { handleClick, disabled, isLoading } = useRequestLocation();
  const { handleClick: requestReboot } = useReboot();
  const {
    handleClick: requestIgnitionLockout,
    disabled: requestIgnitionLockoutDisabled,
    isLoading: requestIgnitionLockoutLoading,
  } = useIgnitionLockout();
  const [showVirtualOdometer, setShowVirtualOdometer] =
    React.useState<boolean>(false);
  const [odometerReading, setOdometerReading] = useState('');
  const {
    handleClick: setVirtualOdometer,
    disabled: setVirtualOdometerDisabled,
    isLoading: setVirtualOdometerLoading,
  } = useVirtualOdometer();

  const handleOdometerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseFloat(event.target.value);
    if (!isNaN(value)) {
      if (value > 1000000) {
        value = 1000000;
      } else if (value < 0) {
        value = 0;
      }
      setOdometerReading(value.toString());
    } else {
      setOdometerReading('');
    }
  };

  React.useEffect(() => {
    if (!isLoading) {
      handleClose();
    }
  }, [isLoading]);

  return (
    <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
      <IconButton
        onClick={event => setDropdownAnchor(event.currentTarget)}
        sx={{
          p: 0.5,
          mt: 1,
          mr: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          border: '2px solid rgba(255, 255, 255, 0.2)',
        }}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={dropdownAnchor}
        open={!!dropdownAnchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem
          onClick={() => {
            handleClick(props.assetId);
            handleClose();
          }}
          disabled={disabled}>
          <ListItemIcon>
            {isLoading ? (
              <CircularProgress size={20} sx={{ ml: 0 }} />
            ) : (
              <TrackChangesIcon fontSize='small' />
            )}
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ fontSize: '.85rem' }}>
            {t('Request location update')}
          </ListItemText>
        </MenuItem>
        {props?.hasVirtualOdometer && (
          <MenuItem
            onClick={() => {
              setShowVirtualOdometer(true);
            }}
            disabled={isPending(requestIgnitionLockoutLoading)}>
            <ListItemIcon>
              {isPending(requestIgnitionLockoutLoading) ? (
                <CircularProgress size={20} sx={{ ml: 0 }} />
              ) : (
                <SpeedIcon fontSize={'small'} />
              )}
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: '.85rem' }}>
              {t('Sync virtual odometer')}
            </ListItemText>
          </MenuItem>
        )}
        {props?.ignitionLockout?.hasIgnitionLockout && (
          <MenuItem
            onClick={() => {
              if (!props?.ignitionLockout?.currentState) {
                setLockoutDevice({
                  assetId: props.assetId,
                  name: props.assetName,
                  stateIgnitionLockedOut: props.ignitionLockout?.currentState,
                });
              } else {
                requestIgnitionLockout(props.assetId, false);
              }
            }}
            disabled={isPending(props.ignitionLockout)}>
            <ListItemIcon>
              {isPending(props.ignitionLockout) ? (
                <CircularProgress size={20} sx={{ ml: 0 }} />
              ) : (
                <>
                  {props.ignitionLockout?.currentState ? (
                    <LockOpenIcon
                      fontSize={'small'}
                      sx={{ color: '#ffbf00' }}
                    />
                  ) : (
                    <LockIcon fontSize={'small'} sx={{ color: '#ffbf00' }} />
                  )}
                </>
              )}
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: '.85rem' }}
              sx={{ color: '#ffbf00' }}>
              {isPending(props.ignitionLockout)
                ? props.ignitionLockout?.currentState
                  ? `Disengaging lockout`
                  : `Engaging lockout`
                : props.ignitionLockout?.currentState
                ? 'Disengage lockout'
                : 'Engage lockout'}
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setRebootDevice({
              assetId: props.assetId,
              assetName: props.assetName,
            });
            handleClose();
          }}
          disabled={disabled}>
          <ListItemIcon>
            {isLoading ? (
              <CircularProgress size={20} sx={{ ml: 0 }} />
            ) : (
              <RestartAltIcon fontSize='small' sx={{ color: '#FD6262' }} />
            )}
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontSize: '.85rem', color: '#FD6262' }}>
            {t('Reboot Device')}
          </ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmDeleteDialog
        deleteText={'sync'}
        icon={<SyncAltIcon sx={{ mr: 1 }} />}
        open={!!showVirtualOdometer}
        onCancel={() => {
          setShowVirtualOdometer(false);
        }}
        onConfirm={() => {
          setVirtualOdometer(props.assetId, parseFloat(odometerReading));
          setOdometerReading('');
          handleClose();
        }}
        confirmButtonEnabled={!!odometerReading}
        title={t('Sync Virtual Odometer')}
        content={
          <>
            {t('vehicle_odometer_reading_input_prompt')}
            <TextField
              autoFocus
              margin='dense'
              id='name'
              label={t('Odometer Reading')}
              type='number'
              fullWidth
              value={odometerReading}
              onChange={handleOdometerChange}
              sx={{ mt: 2 }}
            />
          </>
        }
      />
      <ConfirmDeleteDialog
        deleteText={'engage'}
        confirmButtonColor='#ffbf00'
        icon={<Lock sx={{ color: '#ffbf00 !important', mr: 1 }} />}
        open={!!lockoutDevice}
        onCancel={() => {
          setLockoutDevice(null);
        }}
        onConfirm={() => {
          if (!!lockoutDevice) {
            requestIgnitionLockout(lockoutDevice?.assetId, true);
            handleClose();
          }
        }}
        title={t('Confirm to engage lockout')}
        content={t(
          'Are you sure you want to engage remote lockout for {{name}}?',
          { name: lockoutDevice?.name }
        )}
      />
      <ConfirmDeleteDialog
        deleteText={'reboot'}
        icon={<RestartAltIcon sx={{ mr: 1 }} color='error' />}
        open={!!rebootDevice}
        onCancel={() => {
          setRebootDevice(null);
        }}
        onConfirm={() => {
          if (rebootDevice) requestReboot(rebootDevice?.assetId);
        }}
        title={t('Confirm to reboot device')}
        content={
          <>
            {t('Are you sure you want to reboot')}{' '}
            <b>{rebootDevice?.assetName}</b>?
          </>
        }
      />
    </Box>
  );
};

const isPending = (args: any) => {
  const { lastRequestedAt, lastRequestedState, currentState } = args;
  const requestedAt = moment(lastRequestedAt);
  const fiveMinsAgo = moment().subtract(5, 'minutes');
  if (
    lastRequestedAt !== null &&
    lastRequestedAt !== undefined &&
    lastRequestedState !== null &&
    lastRequestedState !== undefined &&
    requestedAt.isAfter(fiveMinsAgo) &&
    currentState !== lastRequestedState
  ) {
    return true;
  }
  return false;
};

export default DropdownMenu;

import { DataGrid } from '@mui/x-data-grid/DataGrid';
import React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import DownloadIcon from '@mui/icons-material/Download';
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import useActionMenuContext from 'context/ActionMenuContext';
import useUIContext from 'context/UIContext';
import {
  useExportLocalSpeedingReport,
  useLocalSpeedingReport,
} from 'hooks/reports/useSpeedingReport';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import moment from 'moment';
import ReportWrapper from '../ReportWrapper';
import { LocationActivityToolbarWithUrlFilters } from './LocalSpeedingFilters';

const commonColDefOptions = {
  resizable: true,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
  filtrable: false,
  hideable: false,
};

function getColumns({ t }: any): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'assetName',
      headerName: t('Asset'),
      ...commonColDefOptions,
      flex: 1,
    }, // @ts-ignore
    {
      field: 'locationName',
      headerName: t('Location'),
      ...commonColDefOptions,
      flex: 1,
    }, // @ts-ignore
    {
      field: 'speedingThreshold',
      headerName: t('Speed Limit'),
      ...commonColDefOptions,
      flex: 0.75,
      renderCell: (r: any) => {
        return (
          <Box>
            <Typography sx={{ fontSize: '.85rem' }}>
              {`${r?.row.speedingThreshold} km/h`}
            </Typography>
          </Box>
        );
        return;
      },
    }, // @ts-ignore
    {
      field: 'startedAt',
      headerName: t('Start Time'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return moment(r?.row.startedAt).format('YYYY-MM-DD HH:mm:ss');
      },
    }, // @ts-ignore
    {
      field: 'startPosition',
      headerName: t('Start lat/lon'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return (
          <Box>
            <Typography sx={{ fontSize: '.85rem' }}>
              {`${r?.row.startPosition?.x.toFixed(
                5
              )}, ${r?.row.startPosition?.y.toFixed(5)}`}
            </Typography>
          </Box>
        );
      },
    }, // @ts-ignore
    {
      field: 'endedAt',
      headerName: t('End Time'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return moment(r?.row.endedAt).format('YYYY-MM-DD HH:mm:ss');
      },
    }, // @ts-ignore
    {
      field: 'stopPosition',
      headerName: t('Stop lat/lon'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return (
          <Box>
            <Typography sx={{ fontSize: '.85rem' }}>
              {`${r?.row.stopPosition?.x.toFixed(
                5
              )}, ${r?.row.stopPosition?.y.toFixed(5)}`}
            </Typography>
          </Box>
        );
      },
    }, // @ts-ignore
    {
      field: 'alertDurationSeconds',
      headerName: t('Duration'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return (
          <Box>
            <Typography sx={{ fontSize: '.85rem' }}>
              {`${Math.floor(r?.row.alertDurationSeconds)} seconds`}
            </Typography>
          </Box>
        );
      },
    }, // @ts-ignore
  ];
}

const ExportLocalSpeedingReport = ({ filters }: any) => {
  const { t } = useTranslation();
  const { setFlashMessage } = useUIContext();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    data: exportData,
    mutate: exportReport,
    error: exportError,
    reset: exportReset,
    isLoading: isExporting,
    isSuccess: exportSuccess,
  } = useExportLocalSpeedingReport();

  const handleExport = (fileType: any) => {
    handleClose();
    exportReport({
      ...filters,
      locationIds: filters?.locationIds,
      fileType,
      groupIds: filters.groupIds,
    });
  };

  React.useEffect(() => {
    if (exportData && !exportData.exportUrl) {
      setFlashMessage({ message: t(`reports.${exportData.message}`) });
    }
  }, [exportData]);

  return (
    <>
      <Tooltip arrow={true} title={t('reports.export_report')}>
        <IconButton disabled={isExporting} onClick={handleClick}>
          {isExporting ? (
            <CircularProgress size={24} sx={{ mr: 0.25 }} />
          ) : (
            <DownloadIcon />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('excel')}>
              <ListItemText
                primary='.xlsx'
                primaryTypographyProps={{ fontSize: '.85rem' }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('pdf')}>
              <ListItemText
                primaryTypographyProps={{ fontSize: '.85rem' }}
                primary='.pdf'
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      {exportSuccess && exportData && exportData.reportUrl && (
        <iframe
          width='1pxre'
          height='1px'
          style={{ visibility: 'hidden', position: 'absolute' }}
          referrerPolicy='origin'
          src={exportData.reportUrl}></iframe>
      )}
    </>
  );
};

const LocalSpeeding = ({ assetType }: any) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [searchParams] = useComplexSearchParams();

  const { data, isLoading } = useLocalSpeedingReport({
    filterBy: searchParams,
    page: page + 1,
  }) as any;
  const { reset } = useActionMenuContext();

  React.useEffect(() => {
    return () => reset();
  }, []);

  React.useEffect(() => {
    setRowCountState(prevRowCountState =>
      data?.numRecords !== undefined ? data?.numRecords : prevRowCountState
    );
  }, [data?.numRecords, setRowCountState]);

  return (
    <ReportWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Box sx={{ width: '100%' }}>
          <LocationActivityToolbarWithUrlFilters
            assetType={assetType}
            exportComponent={ExportLocalSpeedingReport}
          />
        </Box>
      </Box>
      <DataGrid
        rows={data && data.reports ? data.reports : []}
        getRowId={row => row.alertId}
        columns={getColumns({ t })}
        pageSize={10}
        checkboxSelection={false}
        autoHeight
        loading={isLoading}
        rowHeight={40}
        rowsPerPageOptions={[10]}
        rowCount={rowCountState}
        onPageChange={newPage => setPage(newPage)}
        paginationMode='server'
        pagination
        components={{
          NoRowsOverlay: () => (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              {t('No results')}
            </Stack>
          ),
        }}
      />
      <div style={{ marginTop: '0.5em', lineHeight: '1em' }}>
        <Typography variant={'caption'} sx={{ color: 'rgba(255,255,255,0.5)' }}>
          1. <b>{t('Local Overspeeding')}</b>{' '}
          {t('local_overspeeding_explainer')}
        </Typography>
      </div>
    </ReportWrapper>
  );
};

export default LocalSpeeding;

import { Box, Grid, Paper, Tooltip, Typography } from '@mui/material';
import Gauge from 'components/Gauge';
import InlineLoader from 'components/InlineLoader';
import InfoHelper from 'components/Tooltip/InfoHelper';
import useUIContext from 'context/UIContext';
import {
  useDriverScorecard,
  useDriverScorecardSettings,
} from 'hooks/drivers/useDrivers';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const AllGrades = ['A', 'B', 'C', 'D', 'F'];

const GradeColors = {
  green: '#00b300',
  amber: '#ffbf00',
  red: '#FD6262',
};

const keyMap = {
  harsh_acceleration: 'harshAcceleration',
  harsh_braking: 'harshBraking',
  harsh_cornering: 'harshCornering',
  overspeeding: 'speedingViolation',
};

type keyMapType =
  | 'harsh_acceleration'
  | 'harsh_braking'
  | 'harsh_cornering'
  | 'overspeeding';

export const DriverScorecard = ({
  driverId,
  driverName,
  startTimestamp,
}: {
  driverId: number;
  driverName: string;
  startTimestamp?: Date;
}) => {
  const {
    data: driverScore,
    isLoading,
    isError,
  } = useDriverScorecard({
    id: driverId,
    startTimestamp: startTimestamp || null,
  }) as any;
  const { data: driverScorecardSettings } = useDriverScorecardSettings() as any;
  const [overallScore, setOverallScore] = React.useState(-1);
  const [noData, setNoData] = React.useState(false);
  const { t } = useTranslation();
  const { isMobile } = useUIContext();

  useEffect(() => {
    if (!isError && !isLoading && !driverScore) setNoData(true);
    if (!isLoading && driverScore) {
      setNoData(false);
      setOverallScore(driverScore.overall);
    }
  }, [isLoading, driverScore, isError]);

  if (isError) {
    return <div>{t('something_went_wrong')}</div>;
  }

  const computeGradeTooltipContent = (key: string) => {
    const convertOverspeedingSecondsToHumanTime = (value: any) => {
      const seconds = parseFloat(value);
      if (seconds < 60) {
        return `${Math.round(seconds)} seconds`;
      }
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      if (minutes < 60) {
        return `${minutes} minutes ${Math.round(remainingSeconds)} seconds`;
      }
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours} hours ${remainingMinutes} minutes ${Math.round(
        remainingSeconds
      )} seconds`;
    };

    const mainText = (() => {
      if ('overspeeding' === key) {
        return `${convertOverspeedingSecondsToHumanTime(
          driverScore[key].value
        )} per ${driverScorecardSettings?.thresholds?.config?.per} ${
          driverScorecardSettings?.thresholds?.config?.unit
        }`;
      }
      return `${parseFloat(driverScore[key].value)?.toFixed(2)} events per ${
        driverScorecardSettings?.thresholds?.config?.per
      } ${driverScorecardSettings?.thresholds?.config?.unit}`;
    })();

    const exceededText = (() => {
      if (driverScore[key].score === 'F') {
        if ('overspeeding' === key) {
          return ` which is more than the allowed ${convertOverspeedingSecondsToHumanTime(
            driverScorecardSettings?.thresholds[keyMap[key as keyMapType]]['d']
          )} per ${driverScorecardSettings?.thresholds?.config?.per} ${
            driverScorecardSettings?.thresholds?.config?.unit
          }`;
        }
        return ` which is more than the allowed ${
          driverScorecardSettings?.thresholds[keyMap[key as keyMapType]]['d']
        } per ${driverScorecardSettings?.thresholds?.config?.per} ${
          driverScorecardSettings?.thresholds?.config?.unit
        }`;
      }
      return '';
    })();
    return [`${driverName} had `, mainText, exceededText].join('');
  };

  return (
    <div
      style={{
        borderRadius: 16,
        border: '1px solid rgba(255,255,255,0.1)',
        padding: 10,
        minHeight: '12rem',
        height: '100%',
        position: 'relative',
        display: 'block',
      }}>
      <Typography
        sx={{
          mt: 1,
          ml: 2,
          fontSize: '1.6em',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          zIndex: 10,
        }}
        variant={'subtitle1'}
        color={'white'}
        align='left'
        textAlign={'left'}>
        {t('Driver Scorecard')}
        <InfoHelper label={t('driver_scorecard_description')} sx={{ ml: 1 }} />
      </Typography>
      {!!isError && (
        <Grid
          container
          direction='row'
          spacing={0}
          sx={{ display: 'flex', height: '100%', width: '100%' }}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}>
            <Typography
              variant={'subtitle1'}
              color={'rgba(255,255,255,0.6)'}
              align='center'
              textAlign={'center'}>
              {t('something_went_wrong_were_working_on_it')}
            </Typography>
          </Grid>
        </Grid>
      )}
      {!!isLoading && (
        <Grid
          container
          direction='row'
          spacing={0}
          sx={{ display: 'flex', height: '100%', width: '100%' }}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}>
            <InlineLoader />
          </Grid>
        </Grid>
      )}
      {noData ? (
        <Grid
          container
          direction='row'
          spacing={0}
          sx={{ display: 'flex', height: '100%', width: '100%' }}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}>
            <Typography
              variant={'subtitle1'}
              color={'rgba(255,255,255,0.6)'}
              align='center'
              textAlign={'center'}>
              {t('drivers_report.not_enough_data')}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            position: 'absolute',
            bottom: 25,
          }}>
          <Grid container direction='row' spacing={0} wrap='wrap'>
            <Grid container xs={3}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {driverScorecardSettings && overallScore > -1 && (
                  <Paper
                    sx={{
                      marginTop: {
                        md: '6rem',
                      },
                      width: {
                        xs: '18vw',
                        md: '10vw',
                        lg: '8vw',
                        xl: '8vw',
                      },
                      height: {
                        xs: '18vw',
                        md: '10vw',
                        lg: '8vw',
                        xl: '8vw',
                      },
                      borderRadius: {
                        xs: '18vw',
                        md: '10vw',
                        lg: '8vw',
                        xl: '8vw',
                      },
                      backgroundColor: 'transparent',
                      border: {
                        xs: `10px solid ${
                          GradeColors[
                            driverScorecardSettings.grades[
                              AllGrades[overallScore].toLowerCase()
                            ] as 'green' | 'amber' | 'red'
                          ]
                        }`,
                        lg: `1vw solid ${
                          GradeColors[
                            driverScorecardSettings.grades[
                              AllGrades[overallScore].toLowerCase()
                            ] as 'green' | 'amber' | 'red'
                          ]
                        }`,
                        xl: `1vw solid ${
                          GradeColors[
                            driverScorecardSettings.grades[
                              AllGrades[overallScore].toLowerCase()
                            ] as 'green' | 'amber' | 'red'
                          ]
                        }`,
                      },
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                    }}>
                    <Typography
                      variant={'h1'}
                      fontWeight={'bold'}
                      sx={{
                        fontSize: {
                          xs: '6vw',
                          lg: '4vw',
                        },
                      }}>
                      {AllGrades[overallScore]}
                    </Typography>
                  </Paper>
                )}
                <br />
              </Grid>
            </Grid>
            {/* @ts-ignore */}
            <Grid
              container
              xs={9}
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                pr: 2.5,
              }}>
              {driverScore &&
                driverScorecardSettings &&
                Object.keys(driverScore)
                  .sort()
                  .map((key: string) => {
                    if ('overall' === key) return;
                    return (
                      <Tooltip
                        key={key as string}
                        title={
                          <span style={{ textAlign: 'center' }}>
                            {computeGradeTooltipContent(key as string)}
                          </span>
                        }
                        placement='bottom'
                        arrow>
                        <Grid
                          item
                          xs={3}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-end',
                          }}>
                          <Gauge
                            label={key}
                            grade={driverScore[key].score}
                            color={driverScore[key].color}
                            value={driverScore[key].value}
                          />
                        </Grid>
                      </Tooltip>
                    );
                  })}
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default DriverScorecard;

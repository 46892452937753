import {
  Box,
  Card,
  CardContent,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material';
import { useDashboardWidget } from 'hooks';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Legend,
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export const AssetStaticLineChart = ({
  assetType,
  widgetType,
  chartUnit,
  showTotal = true,
  startTimestamp,
  endTimestamp,
  assetId,
  interval = 0,
}: {
  assetType: string;
  widgetType: string;
  chartUnit: string;
  showTotal?: boolean;
  startTimestamp?: Date;
  endTimestamp?: Date;
  assetId?: number;
  interval?: number;
}) => {
  const DATA_KEY_PRESSURE = 'pressure';
  const DATA_KEY_TEMPERATURE = 'temperature';
  const config: any = {
    [DATA_KEY_PRESSURE]: {
      name: 'Pressure',
      unit: 'psi',
      legend: 'Pressure',
    },
    [DATA_KEY_TEMPERATURE]: {
      name: 'Temperature',
      unit: '°C',
      legend: 'Temperature',
    },
  };

  const { data, isLoading } = useDashboardWidget({
    assetType: assetType,
    widgetType: widgetType,
    startTimestamp: startTimestamp,
    endTimestamp: endTimestamp,
    assetId: assetId,
  }) as any;

  const CustomTooltip = ({ active, payload, label }: any) => {
    const { t } = useTranslation();
    if (active && payload && payload.length) {
      const hasPositiveValues = payload.some((item: any) => item.value > 0);
      return (
        <Card variant='outlined'>
          <CardContent>
            <Typography>
              {moment(label).format('YYYY-MM-DD HH:mm:ss')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            {!hasPositiveValues && (
              <Typography variant='body2'>{t('No data')}</Typography>
            )}

            {hasPositiveValues &&
              payload.map((item: any, index: number) =>
                item.value !== 0 ? (
                  <Typography
                    key={`${item.name}_${index}`}
                    variant='body2'
                    sx={{ color: item.stroke, mb: 0.4 }}>
                    <b>{config[item.name].name}:</b>{' '}
                    <span>
                      {item.value}
                      {config[item.name].unit}
                    </span>
                  </Typography>
                ) : null
              )}
          </CardContent>
        </Card>
      );
    }

    return null;
  };

  function splitData(data: any[], key: 'pressure' | 'temperature'): any[][] {
    const continuousSegments: any[][] = [];
    let currentSegment: any[] = [];

    data.forEach(item => {
      if (item[key] === 0) {
        if (currentSegment.length > 0) {
          continuousSegments.push(currentSegment);
          currentSegment = [];
        }
      } else {
        currentSegment.push({
          startTime: item.startTime,
          [key]: item[key],
        });
      }
    });

    if (currentSegment.length > 0) {
      continuousSegments.push(currentSegment);
    }

    return continuousSegments;
  }

  if (!data || isLoading)
    return <Skeleton variant='rounded' width={'100%'} height={'100%'} />;

  const formattedData = data?.data.map((item: any) => ({
    ...item,
    startTime: moment(item.startTime).toDate().getTime(),
    endTime: moment(item.endTime).toDate().getTime(),
    name: item.tripDate,
    unit: chartUnit,
  }));

  const pressureSegments = splitData(formattedData, DATA_KEY_PRESSURE);
  const temperatureSegments = splitData(formattedData, DATA_KEY_TEMPERATURE);

  const CustomLegend: React.FC<any> = ({ payload, ...props }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {payload.map((entry: any, index: any) => (
          <Box
            key={`item-${index}`}
            sx={{
              listStyle: 'none',
              display: 'flex',
              alignItems: 'center',
              mr: 1,
              color: entry.color ?? 'red',
              fontSize: '.9rem',
            }}>
            <Box
              sx={{
                display: 'inline-block',
                width: '10px',
                height: '2px',
                backgroundColor: entry.color ?? 'red',
                marginRight: '5px',
              }}></Box>
            {entry.value}
          </Box>
        ))}
      </Box>
    );
  };

  const ticks = formattedData.map((item: any) =>
    moment(item.startTime).toDate().getTime()
  );

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <ResponsiveContainer
        width={`${showTotal ? '80%' : '100%'}`}
        height='100%'>
        <RechartsLineChart data={formattedData}>
          <XAxis
            padding={{ left: 10, right: 10 }}
            tick={{ fontSize: 10, textAnchor: 'end', dy: 4 }}
            ticks={ticks}
            dataKey='startTime'
            // domain={['dataMin', 'dataMax']}
            allowDuplicatedCategory={false}
            type='number'
            scale='time'
            // scale='linear'
            interval={10}
            domain={[
              moment(formattedData[0].startTime).toDate().getTime(),
              moment(formattedData[formattedData.length - 1].startTime)
                .toDate()
                .getTime(),
            ]}
            angle={-35}
            // @ts-ignore
            tickFormatter={(value: any, index: number) => ''}
          />
          <YAxis
            yAxisId='left'
            unit={config[DATA_KEY_PRESSURE].unit}
            tick={{ fontSize: 14, fill: '#4BA5F8' }}
            tickLine={{ stroke: '#4BA5F8' }}></YAxis>
          <YAxis
            tick={{ fontSize: 14, fill: '#EB6C67' }}
            tickLine={{ stroke: '#EB6C67' }}
            orientation='right'
            type='number'
            unit={config[DATA_KEY_TEMPERATURE].unit}
            yAxisId='right'></YAxis>
          <Tooltip
            content={<CustomTooltip />}
            filterNull={false}
            allowEscapeViewBox={{ x: true, y: true }}
          />
          <Legend
            payload={[
              {
                value: config[DATA_KEY_PRESSURE].name,
                type: 'line',
                color: '#4BA5F8',
                id: 'pressure-1',
              },
              {
                value: config[DATA_KEY_TEMPERATURE].name,
                type: 'line',
                color: '#EB6C67',
                id: 'temperature-1',
              },
            ]}
            content={<CustomLegend />}
          />
          {pressureSegments.map((segment, index) => (
            <Line
              yAxisId='left'
              type='monotone'
              dataKey={DATA_KEY_PRESSURE}
              data={segment}
              dot={false}
              // activeDot={false}
              key={`pressure-${index}`}
              stroke='#4BA5F8'
            />
          ))}

          {temperatureSegments.map((segment, index) => (
            <Line
              yAxisId='right'
              type='monotone'
              dataKey={DATA_KEY_TEMPERATURE}
              data={segment}
              dot={false}
              // activeDot={false}
              key={`temperature-${index}`}
              stroke='#EB6C67'
            />
          ))}

          <Line
            yAxisId='left'
            type='monotone'
            dataKey={DATA_KEY_PRESSURE}
            data={formattedData}
            dot={false}
            activeDot={false}
            key={`tooltip-line`}
            stroke='transparent'
          />
        </RechartsLineChart>
      </ResponsiveContainer>
      {showTotal && (
        <div style={{ width: '20%', alignSelf: 'center', textAlign: 'center' }}>
          {data?.total && (
            <div style={{ fontSize: '4vh' }}>
              {data.total?.toLocaleString()}
            </div>
          )}
          <div>{chartUnit.charAt(0).toUpperCase() + chartUnit.slice(1)}</div>
        </div>
      )}
    </div>
  );
};

export default AssetStaticLineChart;

import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { DIR } from 'context/UIContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

const drawerWidthOpen = 240;
const drawerWidthClosed = 65;

const fullSizeStyles = {
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  position: 'fixed',
  color: 'white',
  backgroundColor: 'rgba(27, 28, 30, 0.75)',
};
const nonFullSizeStyles = {
  top: 0,
  left: 0,
  paddingLeft: drawerWidthClosed * 2,
  width: 'calc( 100% - 65px )',
  height: '100%',
  minHeight: '100vh',
  position: 'fixed',
  color: 'white',
  backgroundColor: 'rgba(27, 28, 30, 0.75)',
  overflowY: 'scroll',
};

export const PageContent = ({
  fullSize,
  pageTitle,
  titleMenu,
  children,
  isOpen,
  hideHeader,
  direction,
  isMobile = false,
}: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <>
      {fullSize ? (
        <div
          // @ts-ignore
          style={{
            ...fullSizeStyles,
            ...('rtl' === direction ? { left: 0 } : { right: 0 }),
          }}>
          {pageTitle && (
            <h1
              dir={direction}
              style={{
                ...(DIR.RTL === direction
                  ? {
                      textAlign: 'right',
                      paddingRight: '130px',
                    }
                  : {
                      textAlign: 'left',
                      paddingLeft: '130px',
                    }),
              }}>
              {t(pageTitle)}
            </h1>
          )}
          {children}
        </div>
      ) : (
        <div
          // @ts-ignore
          style={{
            ...nonFullSizeStyles,
            ...('rtl' === direction
              ? {
                  left: 0,
                  paddingRight: drawerWidthClosed * 2,
                }
              : {
                  right: 0,
                  paddingLeft: drawerWidthClosed * 2,
                }),
            ...(titleMenu ? { paddingTop: 40 } : { paddingTop: 0 }),
            ...(isMobile && {
              ...fullSizeStyles,
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 60,
              paddingBottom: 50,
            }),
          }}>
          {!hideHeader && pageTitle && (
            <Grid
              container
              alignItems={'center'}
              justifyContent={'space-between'}>
              <Grid>
                <h1
                  dir={direction}
                  style={{
                    textAlign: DIR.RTL === direction ? 'right' : 'left',
                    position: 'static',
                    backgroundColor: 'transparent',
                    marginTop: '.6em',
                    marginBottom: 0,
                    paddingTop: '.4em',
                    paddingBottom: '.4em',
                    ...(DIR.RTL === direction
                      ? {
                          marginRight: isOpen
                            ? drawerWidthOpen - drawerWidthClosed
                            : 0,
                        }
                      : {
                          marginLeft: isOpen
                            ? drawerWidthOpen - drawerWidthClosed
                            : 0,
                        }),
                    display: 'block',
                    zIndex: 99,
                    fontSize: '2em',
                    ...(isMobile && {
                      paddingLeft: '16px',
                    }),
                  }}>
                  {location.state?.title
                    ? t(location.state?.title)
                    : t(pageTitle)}
                </h1>
              </Grid>
              {titleMenu && (
                <Grid sx={{ paddingRight: 10, paddingTop: 2 }}>
                  {titleMenu}
                </Grid>
              )}
            </Grid>
          )}
          {!hideHeader && location.state?.description && (
            <p
              style={{
                ...(DIR.RTL === direction
                  ? {
                      marginRight: isOpen
                        ? drawerWidthOpen - drawerWidthClosed
                        : 0,
                    }
                  : {
                      marginLeft: isOpen
                        ? drawerWidthOpen - drawerWidthClosed
                        : 0,
                    }),
              }}>
              {location.state.description}
            </p>
          )}
          <Box sx={{ paddingTop: 0, paddingBottom: 5 }}>{children}</Box>
        </div>
      )}
    </>
  );
};

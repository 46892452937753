import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Button,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import MissingProfilePicture from 'assets/no-profile-picture-icon.png';
import {
  convertUnitForAssetType,
  getDirectionFromDeg,
  getUnitForAssetType,
} from 'common-web/utils';
import DriverScorecard from 'components/DriverScorecard';
import DateFilter, { IntervalType } from 'components/Filters/DateFilter';
import { LAYERS, PointSource, SOURCES } from 'components/Map';
import Map from 'components/Map/AltMap';
import SingleAssetPoint from 'components/Map/constants/layers/SingleAssetPoint';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import { useLocalStorage } from 'hooks';
import useDriverPictureUploader, { useDriver } from 'hooks/drivers/useDrivers';
import moment from 'moment';
import React, { useRef } from 'react';
import { ReactCountryFlag } from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Trips from 'views/Reports/Trips';
const getCountryISO2 = require('country-iso-3-to-2');

const assetPointLayer: any = SingleAssetPoint({
  id: LAYERS.ASSET_POINTS,
  source: SOURCES.ASSET_LOCATIONS,
});

const DetailsListItem = ({ label, value }: any) => {
  return (
    <ListItem sx={{ py: 0.5 }}>
      <ListItemText>
        <Grid container>
          <Grid item xs={4}>
            <Typography sx={{ fontSize: '.8rem' }}>{label}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography sx={{ fontSize: '.8rem', color: '#a1a1a1' }}>
              {value}
            </Typography>
          </Grid>
        </Grid>
      </ListItemText>
    </ListItem>
  );
};

const layers = [assetPointLayer];

const DriverProfile = () => {
  let { id: driverId } = useParams() as any;
  const { data: driverDetails } = useDriver({ id: driverId }) as any;
  const navigate = useNavigate();
  const [locationsDataSource, setLocationsDataSource] = React.useState<any>();
  const inputFile = useRef(null);
  const [imageUploadHover, setImageUploadHover] = React.useState(false);
  const [fileUploadCompleted, setFileUploadCompleted] = React.useState(0);
  const [storedMapStyle, _] = useLocalStorage('mapStyle', '');
  const {
    mutate: uploadFile,
    isSuccess: uploadSuccess,
    isLoading: isFileUploading,
  } = useDriverPictureUploader();
  const { canWrite } = usePermissions();
  const { t } = useTranslation();
  const { isMobile } = useUIContext();

  const handleImageChange = (file: any) => {
    uploadFile({ request: { file, id: driverId }, setFileUploadCompleted });
  };

  React.useEffect(() => {
    if (!!uploadSuccess) {
      setFileUploadCompleted(0);
    }
  }, [uploadSuccess]);

  React.useEffect(() => {
    if (driverDetails?.lastReport?.position) {
      setLocationsDataSource(
        PointSource(SOURCES.ASSET_LOCATIONS, driverDetails?.lastReport)
      );
    }
  }, [driverDetails]);

  const imageExists = (image_url: string) => {
    return !!image_url;
  };

  const [showAllTrips, setShowAllTrips] = React.useState<boolean>(false);
  const handleShowAllTrips = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAllTrips(event.target.checked);
  };
  const [filters, setFilters] = React.useState<any>({});

  React.useEffect(() => {
    console.log('Filters:', filters);
  }, [filters]);

  return (
    <Box sx={{ overflow: 'scroll', height: '100vh' }}>
      <Box
        sx={{
          width: isMobile ? '92vw' : '80vw',
          minHeight: '100vh',
          m: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          textAlign: 'left',
          justifyContent: 'top',
          ...(isMobile && {
            paddingTop: '60px',
            paddingBottom: '200px',
          }),
        }}>
        <Box sx={{ mt: 1 }}>
          <Box sx={{}}>
            <Button
              size={'small'}
              sx={{ textTransform: 'capitalize' }}
              startIcon={<ArrowBackIcon sx={{ height: '.9em' }} />}
              onClick={() => navigate(-1)}>
              {t('Back')}
            </Button>
          </Box>
        </Box>

        {!driverDetails && (
          <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid item xs={1}>
              <Skeleton variant='text' width={'100%'} height={'4rem'} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant='rounded' width={'100%'} height={'15rem'} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant='rounded' width={'100%'} height={'15rem'} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant='rounded' width={'100%'} height={'15rem'} />
            </Grid>
          </Grid>
        )}

        {driverDetails && (
          <Box sx={{ mb: 8 }}>
            <Typography
              sx={{
                position: 'static',
                backgroundColor: 'transparent',
                width: '100%',
                marginBottom: 0,
                pt: '.4em',
                pb: '.4em',
                display: 'block',
                zIndex: 99,
                fontSize: '2em',
                textAlign: 'left',
              }}>
              {driverDetails?.firstName + ' ' + (driverDetails?.lastName || '')}
            </Typography>
            <Box
              sx={{
                position: 'relative',
                justifyContent: 'center',
              }}>
              <Grid
                container
                direction='row'
                spacing={2}
                sx={{
                  minHeight: {
                    xs: '12rem',
                    md: '12rem',
                    lg: '14rem',
                    xl: '16rem',
                  },
                }}
                wrap='wrap'>
                <Grid
                  item
                  xs={4}
                  md={4}
                  lg={2}
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <div
                    style={{
                      borderRadius: 0,
                      width: '100%',
                      height: '100%',
                      boxSizing: 'border-box',
                      boxShadow: '0px 0px 7px 0px rgba(0,0,0,0.75)',
                      cursor: 'pointer',
                      position: 'relative',
                      aspectRatio: '4/5',
                    }}
                    onMouseOver={() => setImageUploadHover(true)}
                    onMouseOut={() => setImageUploadHover(false)}>
                    <img
                      alt='Profile Picture'
                      src={
                        imageExists(driverDetails?.picture)
                          ? driverDetails?.picture
                          : MissingProfilePicture
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        display: 'block',
                      }}
                      onClick={() =>
                        inputFile?.current &&
                        (inputFile?.current as any).click()
                      }
                    />
                    {!!imageUploadHover && (
                      <div
                        onClick={() =>
                          inputFile?.current &&
                          (inputFile?.current as any).click()
                        } // @ts-ignore
                        style={{
                          width: '100%',
                          padding: 4,
                          boxSizing: 'border-box',
                          position: 'absolute',
                          bottom: 0,
                          backgroundColor: 'rgba(0,0,0,0.65)',
                          color: 'white',
                          textAlign: 'center',
                        }}>
                        {t('Change Picture')}
                      </div>
                    )}
                    {isFileUploading && (
                      <LinearProgress
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                        }}
                        variant='determinate'
                        value={fileUploadCompleted}
                      />
                    )}
                  </div>
                  <input
                    type='file'
                    id='file'
                    ref={inputFile}
                    accept='image/jpeg'
                    style={{ display: 'none' }}
                    onInput={e => {
                      // @ts-ignore
                      handleImageChange(e.target.files[0]);
                    }}
                  />
                </Grid>
                <Grid item xs={8} md={8} lg={4}>
                  <Map
                    hasControls={false}
                    hasDraw={false}
                    center={driverDetails?.lastReport?.position}
                    sources={[locationsDataSource]}
                    layers={layers}
                    images={[]} // important otherwise won't render
                    mapStyle={storedMapStyle}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <DriverScorecard
                    driverId={driverId}
                    driverName={
                      driverDetails?.firstName + ' ' + driverDetails?.lastName
                    }
                    startTimestamp={filters.startTimestamp}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      background: 'rgba(0, 0, 0, 0.25)',
                      borderRadius: '7px',
                      p: 2,
                      mb: 2,
                      flex: 1,
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                    }}>
                    <DateFilter
                      updateFilters={setFilters}
                      selectedDateOption={IntervalType.LAST_THIRTY_DAYS}
                      dateOptions={[
                        IntervalType.LAST_SEVEN_DAYS,
                        IntervalType.LAST_THIRTY_DAYS,
                        IntervalType.LAST_THREE_MONTHS,
                      ]}
                    />
                  </Box>
                  <List
                    sx={{
                      background: 'rgba(0, 0, 0, 0.25)',
                      borderRadius: '7px',
                    }}>
                    <DetailsListItem
                      key={`firstName`}
                      label={t('Firstname')}
                      value={driverDetails?.firstName}
                    />
                    <DetailsListItem
                      key={`lastName`}
                      label={t('Lastname')}
                      value={driverDetails?.lastName}
                    />
                    <DetailsListItem
                      key={`identifier`}
                      label={t('Identifier')}
                      value={driverDetails?.identifier}
                    />
                    <DetailsListItem
                      key={`dob`}
                      label={t('Date of Birth')}
                      value={
                        driverDetails?.dob &&
                        moment(driverDetails?.dob).format('YYYY-MM-DD')
                      }
                    />
                    <DetailsListItem
                      key={`license`}
                      label={t('driver_license_number')}
                      value={driverDetails.licenseNo}
                    />
                    <DetailsListItem
                      key={`email`}
                      label={t('Email')}
                      value={driverDetails.email}
                    />
                    <DetailsListItem
                      key={`phone`}
                      label={t('Phone')}
                      value={driverDetails.phone}
                    />
                    <DetailsListItem
                      key={`comments`}
                      label={t('Comments')}
                      value={driverDetails.comments}
                    />
                    <DetailsListItem
                      key={`lastReport`}
                      label={t('Last Seen')}
                      value={
                        <>
                          {driverDetails?.lastReport?.startTimestamp ? (
                            <>
                              {driverDetails?.firstName}{' '}
                              {t('is on a trip since')}{' '}
                              {moment(
                                driverDetails?.lastReport.startTimestamp
                              ).fromNow()}
                            </>
                          ) : driverDetails?.lastReported ? (
                            <>
                              {driverDetails?.firstName} {t('was last seen')}{' '}
                              {moment(driverDetails?.lastReported).fromNow()}
                            </>
                          ) : (
                            <>
                              {driverDetails?.firstName}{' '}
                              {t('driver_never_been_seen', {
                                firstName: driverDetails?.firstName,
                              })}
                            </>
                          )}
                        </>
                      }
                    />
                    <DetailsListItem
                      key={`assignedToAssets`}
                      label={t('Assigned To')}
                      value={
                        <>
                          {driverDetails.assignedToAssets.length > 0 &&
                            driverDetails.assignedToAssets.map((asset: any) => (
                              <Chip
                                size='small'
                                sx={{ mr: 1, fontSize: 13 }}
                                onClick={() =>
                                  navigate(`/assets/${asset.assetUuid}`)
                                }
                                key={asset.assetId}
                                label={asset.assetName}
                                variant='outlined'
                              />
                            ))}
                        </>
                      }
                    />
                    {null !== driverDetails?.lastReport?.position &&
                      driverDetails?.lastReport?.stateMoving && (
                        <>
                          <DetailsListItem
                            label={t('Location')}
                            value={
                              <>
                                {driverDetails?.lastReport?.position &&
                                driverDetails?.lastReport?.position.lat &&
                                driverDetails?.lastReport?.position.lon ? (
                                  <Typography
                                    sx={{
                                      fontSize: '.8rem',
                                    }}>
                                    {`
                                ${driverDetails?.lastReport?.position?.lat?.toFixed(
                                  5
                                )},
                                ${driverDetails?.lastReport?.position?.lon?.toFixed(
                                  5
                                )}
                              `}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      fontSize: '.8rem',
                                    }}>
                                    {t('Never reported')}
                                  </Typography>
                                )}
                                <Typography sx={{ fontSize: '.8rem' }}>
                                  <ReactCountryFlag
                                    style={{ marginRight: '5px' }}
                                    countryCode={getCountryISO2(
                                      driverDetails?.lastReport?.countryCode
                                    )}
                                  />{' '}
                                  {driverDetails?.lastReport?.formattedAddress}
                                </Typography>
                              </>
                            }
                          />
                        </>
                      )}
                    {null !== driverDetails?.lastReport?.speed &&
                      driverDetails?.lastReport?.stateMoving && (
                        <DetailsListItem
                          label={t('Speed')}
                          value={`${convertUnitForAssetType(
                            'speed',
                            driverDetails?.lastReport?.assetType,
                            driverDetails?.lastReport?.speed
                          )} ${getUnitForAssetType(
                            'speed',
                            driverDetails?.lastReport?.assetType
                          )}`}
                        />
                      )}
                    {null !== driverDetails?.lastReport?.heading &&
                      driverDetails?.lastReport?.stateMoving && (
                        <DetailsListItem
                          label={t('Heading')}
                          value={`${
                            driverDetails?.lastReport?.heading || 0
                          }° (${getDirectionFromDeg(
                            driverDetails?.lastReport?.heading || 0
                          )})`}
                        />
                      )}
                  </List>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box
                    sx={{
                      p: 2,
                      background: 'rgba(0, 0, 0, 0.25)',
                      borderRadius: '7px',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      <Typography
                        sx={{
                          pb: 2,
                        }}>{`${driverDetails?.firstName} ${driverDetails?.lastName}'s trips`}</Typography>
                      <Tooltip
                        title={t('reports.show_all_trips_tooltip')}
                        arrow
                        placement='bottom'>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={showAllTrips}
                                size='small'
                                color='secondary'
                                onChange={handleShowAllTrips}
                              />
                            }
                            label={
                              <Typography sx={{ fontSize: '.8rem', ml: 1 }}>
                                {t('reports.include_all_trips')}
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </Tooltip>
                    </Box>

                    {driverDetails && (
                      <Trips
                        assetType={'vehicle'}
                        showFilters={false}
                        hideColumnIndexes={[0, 2]}
                        startTimestamp={filters.startTimestamp}
                        saveFiltersInQueryString={false}
                        boxed={false}
                        driverId={driverDetails?.driverId}
                        pageSize={6}
                        showAllTrips={showAllTrips}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DriverProfile;

import { SatelliteAlt } from '@mui/icons-material';
import Battery0Bar from '@mui/icons-material/Battery0Bar';
import Battery1Bar from '@mui/icons-material/Battery1Bar';
import Battery2Bar from '@mui/icons-material/Battery2Bar';
import Battery3Bar from '@mui/icons-material/Battery3Bar';
import Battery4Bar from '@mui/icons-material/Battery4Bar';
import Battery5Bar from '@mui/icons-material/Battery5Bar';
import Battery6Bar from '@mui/icons-material/Battery6Bar';
import BatteryFull from '@mui/icons-material/BatteryFull';
import Co2Icon from '@mui/icons-material/Co2';
import GpsFixed from '@mui/icons-material/GpsFixed';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MovingIcon from '@mui/icons-material/Moving';
import { Box, List, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import deviceTypes from '../../../common-web/definitions/deviceTypes.json';

const IGNITION_ON_DEVICES = [
  'orbcomm_st6100',
  'orbcomm_st8100',
  'orbcomm_st9100',
];

const BatteryIcons = [
  Battery0Bar,
  Battery1Bar,
  Battery2Bar,
  Battery3Bar,
  Battery4Bar,
  Battery5Bar,
  Battery6Bar,
  BatteryFull,
];

const getBatteryColor = (value: number) => {
  if (value < 20) {
    return '#ef4655'; // red
  } else if (value < 40) {
    return '#f7aa38'; // orange
  } else if (value < 60) {
    return '#fffa50'; // yellow
  } else {
    return '#5ee432'; // green
  }
};

const getCellColor = (value: number) => {
  if (value < 2) {
    return '#ef4655'; // red
  } else if (value < 3) {
    return '#f7aa38'; // orange
  } else if (value < 4) {
    return '#fffa50'; // yellow
  } else {
    return '#5ee432'; // green
  }
};

const getBatteryIcon = (value: number) => {
  const levelSize = 100 / BatteryIcons.length;
  const index = (() => {
    if (value < levelSize) return 0;
    if (value > 100 - levelSize) return BatteryIcons.length - 1;
    return Math.max(0, Math.floor(value / levelSize) - 1);
  })();
  if (BatteryIcons[index]) {
    return React.createElement(BatteryIcons[index], {
      sx: {
        fontSize: '2rem',
        transform: 'rotate(90deg)',
        color: getBatteryColor(value ?? 100),
      },
    });
  } else {
    return <></>;
  }
};

const getSatelliteIconText = (asset: any) => {
  if (!asset) return '';
  const color = (() => {
    if (asset?.assetRecent?.satSignal === null) {
      const timestamp = moment.utc(asset?.assetRecent?.timestamp);
      const diff = Math.abs(timestamp.diff(this, 'hours'));
      if (diff <= 6) return '#84E153';
      if (diff <= 24) return '#e3ef3a';
      if (diff > 24) return '#e3482d';
    } else {
      if (asset?.assetRecent?.satSignal > 1) return '#84E153';
      if (asset?.assetRecent?.satSignal === 1) return '#e3ef3a';
      return '#e3482d';
    }
  })();
  return <SatelliteAlt sx={{ fontSize: '1.5rem', color }} />;
};

const getSatelliteIconTooltip = (asset: any, t: any) => {
  if (!asset) return '';
  if (asset?.assetRecent?.satSignal === null) {
    return moment(asset?.assetRecent?.timestamp).fromNow();
  } else {
    if (asset?.assetRecent?.satSignal === 0) return t('No Signal');
    if (asset?.assetRecent?.satSignal === 1) return t('Weak Signal');
    if (asset?.assetRecent?.satSignal === 2) return t('OK Signal');
    if (asset?.assetRecent?.satSignal === 3) return t('Good Signal');
    if (asset?.assetRecent?.satSignal === 4) return t('Excellent Signal');
    if (asset?.assetRecent?.satSignal === 5) return t('Excellent Signal');
    return '';
  }
};

const GaugeCluster = ({ asset }: any) => {
  const { t } = useTranslation();

  const elementStyles = {
    border: '1px solid transparent',
    mr: 0.5,
    ml: 0.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const isSatellite = (deviceType: string) => {
    return (
      deviceTypes.deviceTypes
        .find(el => el.id === deviceType)
        ?.transports?.includes('satellite') ?? false
    );
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          background: 'rgba(0, 0, 0, 0.25)',
          borderRadius: '7px',
          px: 1,
          py: 0.5,
          mb: 2,
          flex: 1,
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}>
        {null !== asset?.assetRecent?.stateIgnition &&
          ('vehicle' === asset?.assetType ||
            ('vessel' === asset?.assetType &&
              IGNITION_ON_DEVICES.includes(
                asset?.assetDevice?.deviceType
              ))) && (
            <Box sx={elementStyles}>
              <Tooltip
                arrow={true}
                title={
                  !!asset?.assetRecent?.ignitionState
                    ? 'Ignition On'
                    : 'Ignition Off'
                }>
                <Box
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  {!!asset?.assetRecent?.stateIgnition ||
                  !!asset?.assetRecent?.ignitionState ? (
                    <KeyIcon sx={{ fontSize: '2rem', color: '#84E153' }} />
                  ) : (
                    <KeyOffIcon
                      sx={{ fontSize: '2rem', color: 'text.disabled' }}
                    />
                  )}
                </Box>
              </Tooltip>
            </Box>
          )}
        {asset?.hasIgnitionLockout && (
          <Box sx={elementStyles}>
            <Tooltip
              arrow={true}
              title={`Ignition Lockout ${
                asset?.assetRecent?.stateIgnitionLockedOut
                  ? 'Engaged'
                  : 'Disengaged'
              }`}>
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                {asset?.assetRecent?.stateIgnitionLockedOut ? (
                  <LockIcon
                    sx={{
                      fontSize: '1.6rem',
                      color: '#ffbf00',
                    }}
                  />
                ) : (
                  <LockOpenIcon
                    sx={{
                      fontSize: '1.6rem',
                      color: 'text.disabled',
                    }}
                  />
                )}
              </Box>
            </Tooltip>
          </Box>
        )}
        {isSatellite(asset?.assetDevice?.deviceType) &&
          null !== asset?.assetRecent &&
          null !== asset?.assetRecent?.timestamp && (
            <Box sx={elementStyles}>
              <Tooltip arrow={true} title={getSatelliteIconTooltip(asset, t)}>
                <Box
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  {getSatelliteIconText(asset)}
                </Box>
              </Tooltip>
            </Box>
          )}
        <Box sx={elementStyles}>
          <>
            {(() => {
              let status = 'Stationary';
              let color = 'text.disabled';
              let icon = <GpsFixed sx={{ fontSize: '1.8rem' }} />;

              if (
                asset?.assetRecent?.movingState ||
                asset?.assetRecent?.speed > 0
              ) {
                status = 'Moving';
                color = 'success';
                icon = (
                  <MovingIcon color={'success'} sx={{ fontSize: '1.8rem' }} />
                );
              }
              if (
                !asset?.assetRecent?.movingState &&
                asset?.assetRecent?.idlingState
              ) {
                status = 'Idling';
                color = 'warning';
                icon = (
                  <Co2Icon color={'warning'} sx={{ fontSize: '1.8rem' }} />
                );
              }
              return (
                <Tooltip arrow={true} title={t(status)}>
                  {icon}
                </Tooltip>
              );
            })()}
          </>
        </Box>
        {/* {null !== asset?.assetRecent?.fuelLevel &&
        0 !== asset?.assetRecent?.fuelLevel && (
          <Box sx={{ ...elementStyles, display: 'block', width: '5rem' }}>
            <Gauge value={asset?.assetRecent?.fuelLevel} />
          </Box>
        )}
      {null !== asset?.assetRecent?.odometer &&
        0 !== asset?.assetRecent?.odometer && (
          <Box sx={elementStyles}>
            <Odometer value={asset?.assetRecent?.odometer} />
          </Box>
        )} */}
        {null !== asset?.assetRecent?.batteryLevel &&
          0 !== asset?.assetRecent?.batteryLevel && (
            <Box sx={elementStyles}>
              <Tooltip
                arrow={true}
                title={
                  <>
                    {t('Battery Level:')}{' '}
                    {`${asset?.assetRecent?.batteryLevel}%`}
                  </>
                }>
                {getBatteryIcon(asset?.assetRecent?.batteryLevel)}
              </Tooltip>
            </Box>
          )}
        {null !== asset?.assetRecent?.gsmSignal && (
          <Box sx={elementStyles}>
            <Tooltip
              arrow={true}
              title={
                <>
                  {t('Cell reception:')} {`${asset?.assetRecent?.gsmSignal}/5`}
                </>
              }>
              <Box sx={{ display: 'flex', alignItems: 'end' }}>
                {[...Array(5)].map((x, i) => (
                  <Box
                    key={i}
                    sx={{
                      width: '4px',
                      height: `${0.2 + 1 * (i / 5)}rem`,
                      marginRight: '4px',
                      backgroundColor:
                        i < asset?.assetRecent?.gsmSignal
                          ? getCellColor(asset?.assetRecent?.gsmSignal)
                          : '#CCC',
                    }}></Box>
                ))}
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>

      {((null !== asset?.assetRecent?.odometer &&
        0 !== asset?.assetRecent?.odometer) ||
        (null !== asset?.assetRecent?.fuelLevel &&
          0 !== asset?.assetRecent?.fuelLevel)) && (
        <List
          sx={{
            background: 'rgba(0, 0, 0, 0.25)',
            borderRadius: '7px',
            mb: 2,
            p: 1,
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}>
          {asset?.assetRecent?.odometer && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  letterSpacing: '3px',
                  opacity: 0.3,
                  fontSize: '0.65em',
                  mb: 1,
                }}>
                {t('Odometer')}
              </Typography>
              <Typography>
                {asset?.hasVirtualOdometer ? (
                  <Tooltip title={t('virtual_odometer_info')}>
                    <Box>
                      {asset?.assetRecent?.odometer.toFixed(1)} km<sup>*</sup>
                    </Box>
                  </Tooltip>
                ) : (
                  <Box>{asset?.assetRecent?.odometer.toFixed(1)} km</Box>
                )}
              </Typography>
            </Box>
          )}
          {asset?.assetRecent?.fuelLevel && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  letterSpacing: '3px',
                  opacity: 0.3,
                  fontSize: '0.65em',
                  mb: 1,
                }}>
                {t('Fuel')}
              </Typography>
              <Typography>{asset?.assetRecent?.fuelLevel}%</Typography>
            </Box>
          )}
        </List>
      )}
    </>
  );
};

export default GaugeCluster;
